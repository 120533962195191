<script setup>
import { computed } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { ROUTES } from '../router';
import Card from '../components/base/Card';
import Heading from '../components/base/Heading';
import Text from '../components/base/Text';
import Button from '../components/base/Button';

const router = useRouter();
const route = useRoute();

const handleGoToHome = () => {
  router.push(ROUTES.HOME.path);
};

const isTeacherSession = computed(() => {
  return route?.query?.type === 'teacher';
});
</script>
<template>
  <Card class="py-6 px-10">
    <Heading variant="h2" is="h1" class="text-primary mb-6">
      เสร็จสิ้นการทำแบบทดสอบ
    </Heading>
    <Text v-if="isTeacherSession" class="mb-8">
      <span>รายงานผลการทดสอบจะถูกส่งไปยัง E-mail ของท่านภายใน 7 วัน </span><br />
      <span>หากมีข้อสงสัยประการใดหรือต้องการติดต่อเพื่อจัดการทดสอบให้กับนักเรียนของท่าน</span><br />
      <span>กรุณาติดต่อเบอร์ 091-768-6641</span><br />
    </Text>
    <Text v-else class="mb-8">
      <span>ท่านจะได้รับผลการประเมินภายใน 7 วันทางอีเมล</span><br />
      <span>หากมีข้อสงสัยประการใด กรุณาติดต่อ LINE</span><br />
      <span><a href="https://line.me/ti/p/@edsy.th">@edsy.th ขอบพระคุณค่ะ</a></span
      ><br />
    </Text>
    <Button v-on:click="handleGoToHome">กลับหน้าแรก</Button>
  </Card>
</template>
