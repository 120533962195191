<script setup>
import { reactive } from 'vue';
import { useStore } from 'vuex';
import { ACTIONS } from '../../store';
import Card from '../../components/base/Card';
import Heading from '../../components/base/Heading';
import Text from '../../components/base/Text';
import Button from '../../components/base/Button';
import LoadingModal from '../../components/modals/LoadingModal';
import TestMicSection from './TestMicSection';

const store = useStore();

const TEST_RESULT_KEY = {
  EXCELLENT: 'EXCELLENT',
  GOOD: 'GOOD',
  BAD: 'BAD',
};
const TEST_RESULT = {
  [TEST_RESULT_KEY.EXCELLENT]: {
    text: 'ความดังของเสียงพูดและเสียงรบกวนอยู่ในระดับที่ดี',
    color: '#399B9D',
  },
  [TEST_RESULT_KEY.GOOD]: {
    text: 'ความดังของเสียงพูดและเสียงรบกวนอยู่ในระดับที่ค่อนข้างดี',
    color: '#70C9CB',
  },
  [TEST_RESULT_KEY.BAD]: {
    text: 'ความดังของเสียงพูดและเสียงรบกวนอยู่ในระดับที่ไม่ดี',
    color: '#D21919',
  },
};

const state = reactive({
  audioObj: null,
  loading: false,
  recordingPlaying: false,
  micTestResult: null,
});

const handleStartQuiz = () => {
  store.dispatch(ACTIONS.START_QUIZ);
};

const handleUploadRecording = (audioBlob, data) => {
  const audioUrl = URL.createObjectURL(audioBlob);
  const audioObj = new Audio(audioUrl);
  audioObj.onended = () => {
    state.audioObj.pause();
    state.audioObj = null;
    state.recordingPlaying = false;
  };
  /**
   * Currently, the uploaded file cannot be read from the server
   * TODO: Fix uploaded file and activate this again
   */
  // store.dispatch(ACTIONS.SUBMIT_QUIZ_TEST, {
  //   audioBlob,
  //   quizKey: 'mictest',
  //   bgNoise: data.bgNoise,
  //   speakingNoise: data.speakingNoise,
  //   diff: data.diff,
  // });
  state.audioObj = audioObj;
  const diff = parseInt(data.diff);
  if (diff > 15) {
    state.micTestResult = TEST_RESULT_KEY.EXCELLENT;
  } else if (diff >= 10) {
    state.micTestResult = TEST_RESULT_KEY.GOOD;
  } else {
    state.micTestResult = TEST_RESULT_KEY.BAD;
  }
};

const handleStopRecording = () => {
  state.loading = true;
};

const handleRecordingUploaded = () => {
  state.loading = false;
};

// const handleListenToRecording = () => {
//   state.audioObj.load();
//   state.audioObj.oncanplay = () => {
//     state.audioObj?.play();
//     state.recordingPlaying = true;
//   };
// };

// const handleStopPlayingRecording = () => {
//   state.audioObj.pause();
//   state.recordingPlaying = false;
// };
const handleStartAgain = () => {
  state.audioObj.pause();
  state.audioObj = null;
  state.micTestResult = null;
  state.recordingPlaying = false;
};
</script>
<template>
  <Card class="py-6 px-10">
    <Heading variant="h2" class="text-primary mb-8">
      Instruction
    </Heading>

    <ul class="mb-8 px-4 list-disc">
      <Text is="li" class="mb-2">
        แบบทดสอบประกอบด้วย: การผึกอ่านข้อความ การพูดแนะนำตัว (30 วินาที) และการอธิบายรูปภาพ (1 นาที)
      </Text>
      <Text is="li" class="mb-2">
        Edsy ใช้เทคโนโลยี Speech Recognition และโมเดล Deep Learning
        ในการวิเคราะห์และประเมินผลตามหลักภาษาศาสตร์
      </Text>
      <Text is="li" class="mb-2">
        ในแต่ละข้อท่านจะมีเวลาในการอ่านคำถามและเตรียมตัวเป็นเวลา 1 นาที
        หลังจากนั้นระบบจะเริ่มอัดเสียงโดยอัตโนมัติ
      </Text>
      <Text is="li" class="mb-2">
        เพื่อให้ผลการทดสอบเป็นไปอย่างถูกต้องที่สุด กรุณาทำแบบทดสอบในสถานที่ที่ไม่มีเสียงรบกวน
        และอัดเสียงด้วยเสียงที่ดังชัด ท่านสามารถทดสอบเสียงพูด และเสียงรบกวนเบื้องต้นได้ที่ section
        ด้านล่าง
      </Text>
    </ul>
    <div class="bg-primary-50 rounded-2xl p-6 mb-12">
      <Heading variant="h6" class="mb-6">ทดสอบเสียงพูด และเสียงรบกวน</Heading>

      <ul class="px-4 list-disc mb-6">
        <Text is="li" class="mb-2">
          ในระยะ 5 วินาทีแรก ระบบจะทำการวัดระดับเสียงรบกวน โปรดอยู่ในความเงียบ
        </Text>
        <Text is="li" class="mb-2">
          หลังจากนั้น กรุณาอ่านบทความด้านล่างด้วยระดับเสียงที่จะใช้ในการทดสอบเพื่อวัดระดับเสียงพูด
        </Text>
      </ul>

      <div class="bg-white px-8 py-6 rounded-xl mb-8">
        <Text variant="normal" class="text-center font-medium">
          "Hello, my friends. Today, I am here to do a microphone test. I love to study English. I am feeling great, and I am feeling happy. I hope
          everything works well. We will start soon. One, two, three. Go!"
        </Text>
      </div>

      <div
        v-if="state.audioObj && state.micTestResult"
        :style="`background-color: ${TEST_RESULT[state.micTestResult]?.color}`"
        class="rounded-full shadow-lg px-8 py-4 mb-4"
      >
        <Text class="text-center text-white">
          {{ TEST_RESULT[state.micTestResult]?.text }}
        </Text>
      </div>
      <div class="flex justify-center">
        <TestMicSection
          v-if="!state.audioObj"
          :uploadRecordingFunc="handleUploadRecording"
          :onUploaded="handleRecordingUploaded"
          :onStopRecording="handleStopRecording"
        />

        <!-- <Button
          v-if="state.audioObj && !state.recordingPlaying"
          variant="secondary"
          v-on:click="handleListenToRecording"
        >
          ฟังเสียงที่บันทึก
        </Button> -->
        <!-- <Button
          v-if="state.audioObj && state.recordingPlaying"
          variant="outlined"
          v-on:click="handleStopPlayingRecording"
        >
          ทดสอบอีกครั้ง
        </Button> -->
        <Button v-if="state.audioObj" variant="outlined" v-on:click="handleStartAgain">
          ทดสอบอีกครั้ง
        </Button>
      </div>
    </div>
    <div class="w-full flex justify-center mb-4">
      <Button v-on:click="handleStartQuiz" size="large">เริ่มทำแบบทดสอบ</Button>
    </div>
  </Card>
  <LoadingModal :open="state.loading" text="กำลังประมวลผล" />
</template>
