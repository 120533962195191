import { v4 as uuidv4 } from 'uuid';
import * as api from '../../../api';
import { CODE_TYPE, DATA_KEY, TESTER_DATA_KEY } from '../../../constants';
import router, { ROUTES } from '../../../router';
import { getQuizKeyFromOrder, getQuizOrderFromKey, TOTAL_QUIZ } from '../../../data/quiz';
import { MUTATIONS } from './mutations';
import { GETTERS } from './getters';

export const ACTIONS = {
  REGISTER_SCHOOL: 'registerSchool',
  REGISTER_STUDENT: 'registerStudent',
  REGISTER_TEACHER: 'registerTeacher',
  START_QUIZ: 'startQuiz',
  SUBMIT_QUIZ: 'submitQuiz',
  // TODO: Remove test
  SUBMIT_QUIZ_TEST: 'submitQuizTest',
  END_SESSION: 'endSession',
  CHECK_EXISTING_SESSION: 'checkHasExistingSession',
  UPDATE_WEB_STORAGE: 'updateWebStorage',
  CLEAR_WEB_STORAGE: 'clearWebStorage',
  NEXT_QUIZ: 'nextQuiz',
};

export default {
  async registerSchool(context, { schoolCode }) {
    const { name, type, testId } = await api.validateSchoolCode(schoolCode);

    context.commit(MUTATIONS.UPDATE_SCHOOL, {
      [DATA_KEY.SCHOOL_CODE]: schoolCode,
      [DATA_KEY.SCHOOL_NAME]: name,
      [DATA_KEY.CODE_TYPE]: type,
      [DATA_KEY.TEST_ID]: testId,
    });
    context.dispatch(ACTIONS.UPDATE_WEB_STORAGE);
  },
  async registerStudent(context, data) {
    const studentData = {
      [DATA_KEY.STUDENT_PREFIX]: data[DATA_KEY.STUDENT_PREFIX],
      [DATA_KEY.STUDENT_FIRSTNAME]: data[DATA_KEY.STUDENT_FIRSTNAME],
      [DATA_KEY.STUDENT_LASTNAME]: data[DATA_KEY.STUDENT_LASTNAME],
      [DATA_KEY.STUDENT_NAME]: data[DATA_KEY.STUDENT_NAME],
      [DATA_KEY.STUDENT_GRADE]: data[DATA_KEY.STUDENT_GRADE],
      [DATA_KEY.STUDENT_ROOM]: data[DATA_KEY.STUDENT_ROOM],
    };
    const school = context.getters[GETTERS.SCHOOL];
    const sessionKey = await api.register({
      ...studentData,
      [DATA_KEY.SCHOOL_CODE]: school[DATA_KEY.SCHOOL_CODE],
      [DATA_KEY.SCHOOL_NAME]: school[DATA_KEY.SCHOOL_NAME],
    });

    context.commit(MUTATIONS.SAVE_SESSION_KEY, { [DATA_KEY.SESSION_KEY]: sessionKey });
    context.commit(MUTATIONS.UPDATE_USER, studentData);
    context.dispatch(ACTIONS.UPDATE_WEB_STORAGE);
  },
  async registerTeacher(context, data) {
    const teacherData = {
      [TESTER_DATA_KEY.TESTER_PREFIX]: data[TESTER_DATA_KEY.TESTER_PREFIX],
      [TESTER_DATA_KEY.TESTER_FIRSTNAME]: data[TESTER_DATA_KEY.TESTER_FIRSTNAME],
      [TESTER_DATA_KEY.TESTER_LASTNAME]: data[TESTER_DATA_KEY.TESTER_LASTNAME],
      [TESTER_DATA_KEY.TESTER_NAME]: data[TESTER_DATA_KEY.TESTER_NAME],
      [TESTER_DATA_KEY.TESTER_EMAIL]: data[TESTER_DATA_KEY.TESTER_EMAIL],
      [TESTER_DATA_KEY.TESTER_PHONE_NO]: data[TESTER_DATA_KEY.TESTER_PHONE_NO],
      [DATA_KEY.SCHOOL_NAME]: data[TESTER_DATA_KEY.TESTER_SCHOOL],
    };
    const school = context.getters[GETTERS.SCHOOL];
    const sessionKey = await api.register({
      ...teacherData,
      [DATA_KEY.SCHOOL_CODE]: school[DATA_KEY.SCHOOL_CODE],
    });

    context.commit(MUTATIONS.SAVE_SESSION_KEY, { [DATA_KEY.SESSION_KEY]: sessionKey });
    context.commit(MUTATIONS.UPDATE_USER, teacherData);
    context.dispatch(ACTIONS.UPDATE_WEB_STORAGE);
  },
  async startQuiz(context) {
    const firstQuizKey = getQuizKeyFromOrder(1);
    context.commit(MUTATIONS.UPDATE_CURRENT_QUIZ, { quizKey: firstQuizKey });
    context.dispatch(ACTIONS.UPDATE_WEB_STORAGE);
    router.push(ROUTES.QUIZ.getRoute(firstQuizKey));
  },
  async submitQuiz(context, { audioBlob, quizKey }) {
    const schoolCode = context.getters[GETTERS.SCHOOL_CODE];
    const sessionKey = context.getters[GETTERS.SESSION_KEY];
    const id = uuidv4().replaceAll('-', '');
    const fileName = `${sessionKey}-${schoolCode}-${id}-${quizKey}`;

    try {
      const result = await api.uploadRecording(audioBlob, fileName);
      context.commit(MUTATIONS.UPDATE_QUIZ_STATUS, { quizKey, result: true });
      await context.dispatch(ACTIONS.UPDATE_WEB_STORAGE);
      return result;
    } catch (error) {
      alert(`${error.code}: ${error.message}`);
    }
  },
  async submitQuizTest(context, { audioBlob, quizKey, bgNoise, speakingNoise, diff }) {
    const schoolCode = 'ABCDEF';
    const id = uuidv4().replaceAll('-', '');

    const fileName =
      `TESTMIC101-${bgNoise}-${speakingNoise}-${diff}-${schoolCode}-${id}-${quizKey}`.replaceAll(
        '.',
        '+'
      );

    try {
      await api.uploadRecordingTestMic(audioBlob, fileName);
      context.commit(MUTATIONS.UPDATE_QUIZ_STATUS, { quizKey, result: true });
      await context.dispatch(ACTIONS.UPDATE_WEB_STORAGE);
    } catch (error) {
      alert(`${error.code}: ${error.message}`);
    }
  },
  endSession(context) {
    const school = context.getters[GETTERS.SCHOOL];
    const isTeacherSession = school[DATA_KEY.CODE_TYPE] === CODE_TYPE.TEACHER;
    context.commit(MUTATIONS.RESET_STATE);
    context.dispatch(ACTIONS.CLEAR_WEB_STORAGE);
    router.push(ROUTES.FINISH.getRoute(isTeacherSession ? 'teacher' : undefined));
  },
  checkHasExistingSession(context) {
    if (sessionStorage.getItem([DATA_KEY.SESSION_KEY])) {
      context.commit(MUTATIONS.INITIALIZE_STATE, {
        [DATA_KEY.CODE_TYPE]: sessionStorage.getItem([DATA_KEY.CODE_TYPE]),
        [DATA_KEY.TEST_ID]: sessionStorage.getItem([DATA_KEY.TEST_ID]),
        [DATA_KEY.SCHOOL_CODE]: sessionStorage.getItem([DATA_KEY.SCHOOL_CODE]),
        [DATA_KEY.SCHOOL_NAME]: sessionStorage.getItem([DATA_KEY.SCHOOL_NAME]),
        [DATA_KEY.STUDENT_NAME]: sessionStorage.getItem([DATA_KEY.STUDENT_NAME]),
        [DATA_KEY.STUDENT_GRADE]: sessionStorage.getItem([DATA_KEY.STUDENT_GRADE]),
        [DATA_KEY.STUDENT_ROOM]: sessionStorage.getItem([DATA_KEY.STUDENT_ROOM]),
        [DATA_KEY.SESSION_KEY]: sessionStorage.getItem([DATA_KEY.SESSION_KEY]),
        [DATA_KEY.CURRENT_QUIZ]: sessionStorage.getItem([DATA_KEY.CURRENT_QUIZ]),
        [DATA_KEY.QUIZ_STATUS]: JSON.parse(sessionStorage.getItem([DATA_KEY.QUIZ_STATUS])),
      });
      return true;
    } else {
      context.dispatch(ACTIONS.CLEAR_WEB_STORAGE);
      return false;
    }
  },
  nextQuiz(context) {
    // get current quiz from getter
    const currentQuizKey = context.getters[GETTERS.CURRENT_QUIZ_KEY];
    const currentQuizOrder = getQuizOrderFromKey(currentQuizKey);
    if (currentQuizOrder === TOTAL_QUIZ) {
      context.dispatch(ACTIONS.END_SESSION);
    } else {
      const nextQuizKey = getQuizKeyFromOrder(currentQuizOrder + 1);
      context.commit(MUTATIONS.UPDATE_CURRENT_QUIZ, { quizKey: nextQuizKey });
      context.dispatch(ACTIONS.UPDATE_WEB_STORAGE);
      router.push(ROUTES.QUIZ.getRoute(nextQuizKey));
    }
  },
  /**
   * Belows are methods for web storage (session storage).
   */
  async updateWebStorage(context) {
    return new Promise((resolve) => {
      /**
       * Verify necessary data to store in session storage
       */
      Object.values(DATA_KEY).forEach((key) => {
        if (key === DATA_KEY.QUIZ_STATUS) {
          sessionStorage.setItem(key, JSON.stringify(context.state[key]));
        } else {
          sessionStorage.setItem(key, context.state[key]);
        }
      });
      resolve();
    });
  },
  clearWebStorage() {
    sessionStorage.clear();
  },
};
