import { DATA_KEY, TESTER_DATA_KEY } from '../../../constants';
import { getQuizData } from '../../../data/quiz';

export const GETTERS = {
  SCHOOL: 'school',
  SCHOOL_CODE: 'schoolCode',
  STUDENT: 'student',
  QUIZ_STATUS: 'quizStatus',
  SESSION_KEY: 'sessionKey',
  CURRENT_QUIZ_KEY: 'currentQuizKey',
  CURRENT_QUIZ_DATA: 'currentQuizData',
};

export default {
  school(state) {
    return {
      [DATA_KEY.CODE_TYPE]: state[DATA_KEY.CODE_TYPE],
      [DATA_KEY.SCHOOL_NAME]: state[DATA_KEY.SCHOOL_NAME],
      [DATA_KEY.SCHOOL_CODE]: state[DATA_KEY.SCHOOL_CODE],
      [DATA_KEY.TEST_ID]: state[DATA_KEY.TEST_ID],
    };
  },
  schoolCode(state) {
    return state[DATA_KEY.SCHOOL_CODE];
  },
  student(state) {
    return {
      [TESTER_DATA_KEY.TESTER_NAME]: state[TESTER_DATA_KEY.TESTER_NAME],
      [TESTER_DATA_KEY.TESTER_GRADE]: state[TESTER_DATA_KEY.TESTER_GRADE],
      [TESTER_DATA_KEY.TESTER_ROOM]: state[TESTER_DATA_KEY.TESTER_ROOM],
      [TESTER_DATA_KEY.TESTER_EMAIL]: state[TESTER_DATA_KEY.TESTER_EMAIL],
      [TESTER_DATA_KEY.TESTER_PHONE_NO]: state[TESTER_DATA_KEY.TESTER_PHONE_NO],
      [TESTER_DATA_KEY.TESTER_SCHOOL]: state[TESTER_DATA_KEY.TESTER_SCHOOL],
    };
  },
  quizStatus(state) {
    return state[DATA_KEY.QUIZ_STATUS];
  },
  sessionKey(state) {
    return state[DATA_KEY.SESSION_KEY];
  },
  currentQuizKey(state) {
    return state[DATA_KEY.CURRENT_QUIZ];
  },
  currentQuizData(state) {
    const testId = state[DATA_KEY.TEST_ID];
    if (state[DATA_KEY.CURRENT_QUIZ]) {
      return getQuizData(testId)[state[DATA_KEY.CURRENT_QUIZ]];
    } else {
      return null;
    }
  },
};
