<!--
  Button Component

  - Props
    - variant controls colors, background, border.
    - size controls spacing, font-size, font-weight.

  - To override any styles in-place e.g. making full-width on some pages, pass the w-full class into the component.
  - Do not directly modify any styles that is specific to some use-cases on this component unless it is appropriate to define new variant, size, or other new props.
-->

<script setup>
import { computed, defineProps } from 'vue';
const props = defineProps({
  variant: {
    type: String,
    default: 'primary',
    validator: (value) => {
      return value.match(/(primary|secondary|accent|outlined|flat|flat-white)/);
    },
  },
  size: {
    type: String,
    default: 'normal',
    validator: (value) => {
      return value.match(/(normal|large|small)/);
    },
  },
  is: {
    type: String,
    default: 'button',
    validator: (value) => {
      return value.match(/(button|router-link)/);
    },
  },
  class: {
    type: String,
    default: '',
  },
  style: {
    type: String,
    default: '',
  },
});

const classes = computed(() => {
  return `edsy-button ${props.variant} ${props.size} ${props.class}`;
});
</script>

<template>
  <component :is="is" :class="classes" v-bind="$attrs">
    <slot></slot>
  </component>
</template>

<style scoped>
button,
.edsy-button {
  height: fit-content;
  @apply flex justify-center items-center border rounded-full transition duration-150 outline-none;
}
.edsy-button[disabled] {
  cursor: not-allowed;
  @apply bg-grey-400 border-grey-400 text-white;
  @apply hover:bg-grey-400 hover:border-grey-400 hover:text-white;
}

.primary {
  @apply bg-primary border-primary text-white;
  /* hover */
  @apply hover:bg-primary-700 hover:border-primary-700;
}

.secondary {
  @apply bg-white border-white text-primary;
  /* hover */
  @apply hover:bg-primary-100 hover:border-primary-100;
}

.accent {
  @apply bg-yellow-600 border-yellow-600 text-grey-900;
  @apply hover:bg-yellow-700 hover:border-yellow-700;
}

.outlined {
  @apply bg-transparent text-primary border-primary;
  /* hover */
  @apply hover:bg-primary-50 hover:text-primary-700;
}

.flat {
  @apply bg-transparent text-primary-500 border-none;
  /* hover */
  @apply hover:text-primary-700;
}

.flat-white {
  @apply bg-transparent text-white border-none;
  /* hover */
  @apply hover:text-opacity-50;
}

.large {
  @apply px-8 py-2 text-lg font-medium;
}

.normal {
  @apply px-4 py-2 text-base font-medium;
}

.small {
  @apply px-3 py-2 text-xs font-medium;
}

.flat.large,
.flat.normal,
.flat-white.large,
.flat-white.normal {
  @apply px-0 py-0;
}
</style>
