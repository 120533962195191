<script setup>
import { defineProps, computed } from 'vue';
import { useStore } from 'vuex';
import { getQuizData } from '../../data/quiz.js';
import { GETTERS } from '../../store';
import Heading from '../base/Heading';
import Modal from '../base/Modal';
import Button from '../base/Button';
import Text from '../base/Text';
import { DATA_KEY } from '../../constants/index.js';

const props = defineProps({
  open: { type: Boolean, default: false },
  username: { type: String, default: '' },
  schoolName: { type: String, default: '' },
  currentQuiz: { type: String, default: '' },
  isTeacherSession: { type: Boolean, default: false },
  onConfirm: { type: Function, default: () => {} },
  onCancel: { type: Function, default: () => {} },
});

const store = useStore();

const quizData = computed(() => {
  if (props.currentQuiz) {
    const school = store?.getters[GETTERS.SCHOOL];
    const testId = school[DATA_KEY.TEST_ID] || "TEST-A";
    return getQuizData(testId)[props.currentQuiz];
  }
  return null;
});
</script>

<template>
  <Modal :open="props.open" :closeIcon="false">
    <template v-slot:title>
      <Heading variant="h3" class="text-primary">
        คุณมี Session ที่ทำค้างไว้ ต้องการทำต่อหรือไม่?
      </Heading>
    </template>
    <template v-slot:body>
      <div class="py-8">
        <Heading variant="h6" class="mb-4 text-primary">ข้อมูล Session</Heading>
        <Text v-if="!props.isTeacherSession">
          <span class="font-semibold">โรงเรียน:</span> {{ props.schoolName }}
        </Text>
        <Text><span class="font-semibold">ผู้ทำแบบทดสอบ:</span> {{ props.username }}</Text>
        <Text v-if="quizData">
          <span class="font-semibold">แบบทดสอบล่าสุดที่ทำ:</span> ข้อที่ {{ quizData.index }}
          {{ quizData.name }}
        </Text>
      </div>
    </template>
    <template v-slot:footer>
      <div class="flex gap-2 justify-end items-center">
        <Button variant="outlined" v-on:click="onCancel">เริ่ม Session ใหม่</Button>
        <Button variant="primary" v-on:click="onConfirm">ทำ Session นี้ต่อ</Button>
      </div>
    </template>
  </Modal>
</template>

<style scoped></style>
