import { createApp } from 'vue';
import VueClickAway from 'vue3-click-away';

import './assets/tailwind.css';

import router from './router';
import store from './store/index';

import App from './App.vue';

const app = createApp(App);

app.use(router);
app.use(store);
app.use(VueClickAway);

app.mount('#app');
