import { createRouter, createWebHistory } from 'vue-router';

import Home from './pages/Home.vue';
import Registration from './pages/Registration/Registration.vue';
import Instruction from './pages/Instruction/Instruction.vue';
import Finish from './pages/Finish.vue';
import Quiz from './pages/Quiz.vue';
import MicTest from './pages/MicTest/MicTest.vue';

const ROUTES = {
  HOME: { path: '/', component: Home },
  REGISTRATION: { path: '/register', component: Registration },
  INSTRUCTION: { path: '/instruction', component: Instruction },
  QUIZ: { path: '/quiz/:quizKey', component: Quiz, getRoute: (quizKey) => `/quiz/${quizKey}` },
  FINISH: {
    path: '/finish',
    component: Finish,
    getRoute: (type) => `/finish${type ? `?type=${type}` : ''}`,
  },
  MIC_TEST: {
    path: '/mic-test',
    component: MicTest,
    getRoute: () => `/mic-test`,
  },
};

const router = createRouter({
  history: createWebHistory(),
  routes: [...Object.values(ROUTES)],
});

export { ROUTES };
export default router;
