import { DATA_KEY, TESTER_DATA_KEY } from '../../../constants';

export const MUTATIONS = {
  UPDATE_SCHOOL: 'updateSchool',
  UPDATE_USER: 'updateUser',
  RESET_STATE: 'resetState',
  SAVE_SESSION_KEY: 'saveSessionKey',
  UPDATE_QUIZ_STATUS: 'updateQuizStatus',
  UPDATE_CURRENT_QUIZ: 'updateCurrentQuiz',
  INITIALIZE_STATE: 'initializeState',
};

export default {
  saveSessionKey(state, payload) {
    state[DATA_KEY.SESSION_KEY] = payload[DATA_KEY.SESSION_KEY];
  },
  updateSchool(state, payload) {
    state[DATA_KEY.SCHOOL_NAME] = payload[DATA_KEY.SCHOOL_NAME];
    state[DATA_KEY.SCHOOL_CODE] = payload[DATA_KEY.SCHOOL_CODE];
    state[DATA_KEY.CODE_TYPE] = payload[DATA_KEY.CODE_TYPE];
    state[DATA_KEY.TEST_ID] = payload[DATA_KEY.TEST_ID];
  },
  updateUser(state, payload) {
    state[TESTER_DATA_KEY.TESTER_NAME] = payload[TESTER_DATA_KEY.TESTER_NAME];
    state[TESTER_DATA_KEY.TESTER_GRADE] = payload[TESTER_DATA_KEY.TESTER_GRADE];
    state[TESTER_DATA_KEY.TESTER_ROOM] = payload[TESTER_DATA_KEY.TESTER_ROOM];
    state[TESTER_DATA_KEY.TESTER_EMAIL] = payload[TESTER_DATA_KEY.TESTER_EMAIL];
    state[TESTER_DATA_KEY.TESTER_PHONE_NO] = payload[TESTER_DATA_KEY.TESTER_PHONE_NO];
    state[TESTER_DATA_KEY.TESTER_SCHOOL] = payload[TESTER_DATA_KEY.TESTER_SCHOOL];
  },
  updateQuizStatus(state, payload) {
    const quizKey = payload.quizKey;
    const result = payload.result;

    state[DATA_KEY.QUIZ_STATUS][quizKey] = result;
  },
  updateCurrentQuiz(state, { quizKey }) {
    state[DATA_KEY.CURRENT_QUIZ] = quizKey;
  },
  initializeState(state, payload) {
    state[DATA_KEY.CODE_TYPE] = payload[DATA_KEY.CODE_TYPE];
    state[DATA_KEY.SCHOOL_CODE] = payload[DATA_KEY.SCHOOL_CODE];
    state[DATA_KEY.SCHOOL_NAME] = payload[DATA_KEY.SCHOOL_NAME];
    state[DATA_KEY.TEST_ID] = payload[DATA_KEY.TEST_ID];
    state[DATA_KEY.STUDENT_NAME] = payload[DATA_KEY.STUDENT_NAME];
    state[DATA_KEY.STUDENT_GRADE] = payload[DATA_KEY.STUDENT_GRADE];
    state[DATA_KEY.STUDENT_ROOM] = payload[DATA_KEY.STUDENT_ROOM];
    state[DATA_KEY.SESSION_KEY] = payload[DATA_KEY.SESSION_KEY];
    state[DATA_KEY.CURRENT_QUIZ] = payload[DATA_KEY.CURRENT_QUIZ];
    state[DATA_KEY.QUIZ_STATUS] = payload[DATA_KEY.QUIZ_STATUS];
  },
  resetState(state) {
    state[DATA_KEY.CODE_TYPE] = '';
    state[DATA_KEY.SCHOOL_CODE] = '';
    state[DATA_KEY.SCHOOL_NAME] = '';
    state[DATA_KEY.TEST_ID] = '';
    state[DATA_KEY.STUDENT_NAME] = '';
    state[DATA_KEY.STUDENT_GRADE] = '';
    state[DATA_KEY.STUDENT_ROOM] = '';
    state[DATA_KEY.SESSION_KEY] = '';
    state[DATA_KEY.CURRENT_QUIZ] = '';
    state[DATA_KEY.QUIZ_STATUS] = {};
  },
};
