<script setup>
import { computed, onMounted, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { ROUTES } from '../../router';
import { ACTIONS, GETTERS } from '../../store';
import { DATA_KEY, CODE_TYPE, TESTER_DATA_KEY } from '../../constants';
import Heading from '../../components/base/Heading';
import Card from '../../components/base/Card';
import LoadingModal from '../../components/modals/LoadingModal';
import RegistrationForm from './RegistrationForm';
import RegistrationTeacherForm from './RegistrationTeacherForm';

const router = useRouter();
const store = useStore();
const state = reactive({
  loading: false,
});

onMounted(() => {
  const school = store.getters[GETTERS.SCHOOL];
  if (!school[DATA_KEY.SCHOOL_NAME] || !school[DATA_KEY.SCHOOL_CODE]) {
    router.push(ROUTES.HOME.path);
  }
});

const handleRegister = async (formData) => {
  state.loading = true;
  try {
    await store.dispatch(ACTIONS.REGISTER_STUDENT, {
      [DATA_KEY.STUDENT_PREFIX]: formData.prefix,
      [DATA_KEY.STUDENT_FIRSTNAME]: formData.firstname,
      [DATA_KEY.STUDENT_LASTNAME]: formData.lastname,
      [DATA_KEY.STUDENT_NAME]: formData.fullname,
      [DATA_KEY.STUDENT_GRADE]: formData.grade,
      [DATA_KEY.STUDENT_ROOM]: formData.room,
    });
    router.push(ROUTES.INSTRUCTION.path);
  } catch (error) {
    alert(error.message);
  }
  state.loading = false;
};

const handleRegisterTeacher = async (formData) => {
  state.loading = true;
  try {
    await store.dispatch(ACTIONS.REGISTER_TEACHER, {
      [TESTER_DATA_KEY.TESTER_PREFIX]: formData.prefix,
      [TESTER_DATA_KEY.TESTER_FIRSTNAME]: formData.firstname,
      [TESTER_DATA_KEY.TESTER_LASTNAME]: formData.lastname,
      [TESTER_DATA_KEY.TESTER_NAME]: formData.fullname,
      [TESTER_DATA_KEY.TESTER_SCHOOL]: formData.school,
      [TESTER_DATA_KEY.TESTER_EMAIL]: formData.email,
      [TESTER_DATA_KEY.TESTER_PHONE_NO]: formData.phoneNo,
    });
    router.push(ROUTES.INSTRUCTION.path);
  } catch (error) {
    alert(error.message);
  }
  state.loading = false;
};

const schoolData = computed(() => {
  const school = store?.getters[GETTERS.SCHOOL];
  return school;
});

const schoolName = computed(() => {
  if (schoolData.value) return schoolData.value[DATA_KEY.SCHOOL_NAME];
  return undefined;
});

const isTeacherRegistration = computed(() => {
  if (schoolData.value) return schoolData.value[DATA_KEY.CODE_TYPE] === CODE_TYPE.TEACHER;
  return false;
});
</script>

<template>
  <Card class="p-10">
    <Heading variant="h2" class="text-primary mb-2">
      {{ isTeacherRegistration ? 'ลงทะเบียนอาจารย์' : 'ลงทะเบียนนักเรียน' }}
    </Heading>
    <Heading variant="h5" class="text-primary mb-8">
      {{ !isTeacherRegistration ? 'โรงเรียน: ' : '' }} {{ schoolName }}
    </Heading>

    <RegistrationTeacherForm v-if="isTeacherRegistration" @saveData="handleRegisterTeacher" />
    <RegistrationForm v-else @saveData="handleRegister" :schoolName="schoolName"></RegistrationForm>
  </Card>
  <LoadingModal :open="state.loading" />
</template>

<style scoped></style>
