<script setup>
import { defineProps, computed } from 'vue';
import Heading from '../base/Heading.vue';
import Modal from '../base/Modal.vue';
import Button from '../base/Button.vue';

const props = defineProps({
  open: { type: Boolean, default: false },
  ctaButtonText: { type: String, default: 'ข้อถัดไป' },
  onClickCta: { type: Function, default: () => {} },
  isLastQuiz: { type: Boolean, default: false },
});

const subtitle = computed(() => {
  return props.isLastQuiz ? 'คุณทำข้อสอบครบทุกข้อแล้ว' : 'ไปข้อต่อไปกันเลย';
});

const buttonText = computed(() => {
  return props.isLastQuiz ? 'จบการทดสอบ' : 'ไปข้อต่อไป';
});
</script>

<template>
  <Modal :open="props.open" :closeIcon="false">
    <template v-slot:title>
      <Heading variant="h4">บันทึกเสียงเรียบร้อย</Heading>
    </template>
    <template v-slot:body>
      <div class="flex flex-col items-center pt-10">
        <Heading variant="h2" class="mb-2 text-primary text-center">เก่งมาก!</Heading>
        <Heading variant="h3" class="mb-12 text-primary text-center">{{ subtitle }}</Heading>
        <Button v-on:click="props.onClickCta" size="large">{{ buttonText }}</Button>
      </div>
    </template>
  </Modal>
</template>

<style scoped></style>
