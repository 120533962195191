import { QUIZ_KEY } from "../constants";
import { QUIZ_TYPE } from "./common";

export const QUIZ_DATA_SETA = {
    [QUIZ_KEY.QUIZ_1]: {
      index: 1,
      type: QUIZ_TYPE.READING,
      name: 'แบบทดสอบการอ่านออกเสียง',
      prompt: [
        "Two gray kittens are on a roof",
        "That woman with a blue shirt is very cold",
        "A red ball is under the table",
        "Today is a great gift",
        "What do you want in life",
        "Do you want to buy a house with a lift ",
        "Do you like to make cookies at the mall",
        "Why does his child like a black shirt"
      ],
      timeLimit: 60,
      preRecordingTime: 60,
    },
    [QUIZ_KEY.QUIZ_2]: {
      index: 2,
      type: QUIZ_TYPE.READING,
      name: 'แบบทดสอบการอ่านออกเสียง',
      prompt: [
        "My favorite subject is English.",
        "I play basketball, tennis, and football.",
        "My mom and I have a pet rabbit.",
        "The vase has a plant in it.",
        "The bell always rings at five.",
        "My favorite food is in my backpack.",
        "The cat is playing with a red ball.",
        "I love to read books at the library.",
        "The van stops to pick up the students.",
        "The kitten stays on my lap when I study."
      ],
      timeLimit: 60,
      preRecordingTime: 60,
    },
    [QUIZ_KEY.QUIZ_3]: {
      index: 3,
      type: QUIZ_TYPE.OPEN_ENDED,
      name: 'แบบทดสอบการอ่านออกเสียง',
      prompt: [
        "Please introduce yourself in English.",
        "-----",
        "กรุณาแนะนำตัวเองเป็นภาษาอังกฤษ ยกตัวอย่าง ชื่ออะไร อายุเท่าไหร่ เกิดที่ไหน เรียนที่ไหน เวลาว่างชอบทำอะไร เป็นต้น"
      ],
      timeLimit: 30,
      preRecordingTime: 60,
    },
    [QUIZ_KEY.QUIZ_4]: {
      index: 4,
      type: QUIZ_TYPE.OPEN_ENDED,
      name: 'แบบทดสอบการพูด',
      prompt: [
        "What's your favorite animal? What does it look like? And why do you like it?",
        "-----",
        "โปรดเล่าเกี่ยวกับสัตว์ที่คุณชอบ อธิบายว่าสัตว์ตัวหน้านี้มีรูปร่างหน้าตาเป็นอย่างไร ทำไมคุณถึงชอบสัตว์ตัวนี้ เป็นต้น"
      ],
      timeLimit: 30,
      preRecordingTime: 60,
    },
    [QUIZ_KEY.QUIZ_5]: {
      index: 5,
      type: QUIZ_TYPE.OPEN_ENDED,
      name: 'แบบทดสอบการพูด',
      prompt: [
        "Tell me about your favorite movie or favorite cartoon: What is it about? And why do you like it?",
        `-----`,
        "โปรดเล่าเกี่ยวกับหนังหรือการ์ตูนที่คุณชอบ อธิบายว่าเป็นเรื่องเกี่ยวกับอะไร ทำไมคุณถึงชอบเรื่องนี้ เป็นต้น"
      ],
      timeLimit: 30,
      preRecordingTime: 60,
    },
  };