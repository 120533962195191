<script setup>
import { defineProps, reactive } from 'vue';
import { useStore } from 'vuex';
import { ACTIONS } from '../../store';
import QuizCard from './QuizCard';
import RecordingButton from './RecordingButton';
import TimeupModal from './TimeupModal';
import FinishModal from './FinishModal';
import LoadingModal from '../modals/LoadingModal';

const store = useStore();
const props = defineProps({
  onQuizFinished: {
    type: Function,
    default: () => {},
  },
  quizKey: {
    type: String,
  },
  title: {
    type: String,
  },
  subtitle: {
    type: String,
  },
  instructions: {
    type: Array,
  },
  timeLimit: {
    type: Number,
    default: 60,
  },
  preRecordingTime: {
    type: Number,
    default: 15,
  },
  onNext: {
    type: Function,
    default: () => {},
  },
  isLastQuiz: {
    type: Boolean,
    default: false,
  },
});
const state = reactive({
  isNextButtonDisabled: true,
  showRecordingButton: true,
  timeupModalOpen: false,
  finishModalOpen: false,
  loadingModalOpen: false,
});

const handleFinishRecording = () => {
  state.isNextButtonDisabled = false;
  state.showRecordingButton = false;
  state.timeupModalOpen = false;
  state.finishModalOpen = true;
};

const handleUploaded = () => {
  state.loadingModalOpen = false;
  state.timeupModalOpen = false;
};

const uploadRecording = async (audioBlob) => {
  await store.dispatch(ACTIONS.SUBMIT_QUIZ, { audioBlob, quizKey: props.quizKey });
};

const handleTimeup = () => {
  state.timeupModalOpen = true;
};

const handleNext = () => {
  props.onNext();
};

const handleStopRecording = () => {
  // TimeupModal also have loading text.
  if (!state.timeupModalOpen) {
    state.loadingModalOpen = true;
  }
};
</script>

<template>
  <QuizCard
    :subtitle="props.subtitle"
    :title="props.title"
    :instructions="props.instructions"
    :nextButtonDisabled="state.isNextButtonDisabled"
  >
    <div class="bg-primary-50 px-6 py-8 rounded-2xl">
      <slot></slot>
      <div v-if="state.showRecordingButton" class="flex gap-4 justify-center">
        <RecordingButton
          :timeLimit="timeLimit"
          :preRecordingTime="preRecordingTime"
          :autoRecord="true"
          :onFinish="handleFinishRecording"
          :onUploaded="handleUploaded"
          :onStopRecording="handleStopRecording"
          :uploadRecordingFunc="uploadRecording"
          :onTimeup="handleTimeup"
        />
      </div>
    </div>
  </QuizCard>
  <TimeupModal :open="state.timeupModalOpen" />
  <FinishModal
    :open="state.finishModalOpen"
    :isLastQuiz="props.isLastQuiz"
    :onClickCta="handleNext"
  />
  <LoadingModal :open="state.loadingModalOpen" text="กำลังอัปโหลดเสียงที่บันทึก" />
</template>

<style scoped>
.record-image {
  max-width: 150px;
  object-fit: contain;
  width: 100%;
}
</style>
