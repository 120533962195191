import { QUIZ_KEY } from "../constants";
import { QUIZ_TYPE } from "./common";

export const QUIZ_DATA_SETC = {
    [QUIZ_KEY.QUIZ_1]: {
      index: 1,
      type: QUIZ_TYPE.READING,
      name: 'แบบทดสอบการอ่านออกเสียง',
      prompt: [
        "My favorite subject is English.",
        "I play basketball, tennis, and football.",
        "My mom and I have a pet rabbit.",
        "The vase has a plant in it.",
        "The bell always rings at five.",
        "My favorite food is in my backpack.",
        "The cat is playing with a red ball.",
        "I love to read books at the library.",
        "The van stops to pick up the students.",
        "The kitten stays on my lap when I study."
      ],
      timeLimit: 60,
      preRecordingTime: 60,
    },
    [QUIZ_KEY.QUIZ_2]: {
      index: 2,
      type: QUIZ_TYPE.READING,
      name: 'แบบทดสอบการอ่านออกเสียง',
      prompt: [
        "He had to push hard to fasten his belt.",
        "She had a thin slice of cheese on her sandwich.",
        "She should choose the fresh fish for breakfast.",
        "The ship caught five fish in the North Sea.",
        "I had to choose between the thin and thick socks.",
        "I think we should try the free sample of fried chicken.",
        "His teeth felt smooth after he used the new toothbrush.",
        "A cheerful smile can improve communication and spread happiness.",
        "They will have a huge advantage with the advantageous job offer.",
        "The journalist had a difficult time catching up with the scientist.",
        "The chair was difficult to move because of the thick carpet.",
        "The smooth cheese melted in their mouths as they took a bite."
      ],
      timeLimit: 60,
      preRecordingTime: 60,
    },
    [QUIZ_KEY.QUIZ_3]: {
      index: 3,
      type: QUIZ_TYPE.DESCRIBE,
        name: 'แบบทดสอบการอธิบาย',
      prompt: [
        "Find the differences in the pictures.",
        "Look at both pictures and describe how the second one is different from the first one.",
        "-----",
        'Prompt: “In the second picture, the boy in the green t-shirt _______."',
        ""
      ],
      imagePromptUrl: '/assets/images/class.png',
      timeLimit: 60,
      preRecordingTime: 60,
    },
    [QUIZ_KEY.QUIZ_4]: {
      index: 4,
      type: QUIZ_TYPE.OPEN_ENDED,
      name: 'แบบทดสอบการพูด',
      prompt: [
        "Would you like to have a shorter or longer school holiday? Why? How long would you like it to be? How would you spend your time during the holiday?",
        `-----`,
      ],
      timeLimit: 30,
      preRecordingTime: 60,
    },
    [QUIZ_KEY.QUIZ_5]: {
      index: 5,
      type: QUIZ_TYPE.OPEN_ENDED,
      name: 'แบบทดสอบการพูด',
      prompt: [
        "What is the most difficult subject in school for you? Why? Could you explain the things you struggle with and how you handle them?",
        `-----`,
      ],
      timeLimit: 30,
      preRecordingTime: 60,
    },
  };