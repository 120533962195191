<script setup>
import { watch, defineProps, onUnmounted, computed } from 'vue';
import Button from '../base/Button';

const props = defineProps({
  open: {
    type: Boolean,
  },
  onClose: {
    type: Function,
  },
  size: {
    type: String,
    default: 'md',
    validator: (value) => {
      return value.match(/(xs|sm|md)/);
    },
  },
  fluid: {
    type: Boolean,
  },
  title: {
    type: String,
  },
  closeIcon: {
    type: Boolean,
    default: true,
  },
});

onUnmounted(() => {
  document.body.classList.remove('modal-open');
});

watch(
  () => props.open,
  () => {
    if (props.open) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }
);

/**
 * ClickAway is not working.
 * TODO: Fix this.
 */
const handleClickAway = () => {
  if (props.closeIcon) {
    props.onClose();
  }
};
const handleClose = () => {
  if (props.closeIcon) {
    props.onClose();
  }
};

const containerClasses = computed(() => {
  return `modal-container ${props.size} ${props.fluid ? 'fluid' : ''}`;
});
</script>

<template>
  <Transition>
    <div v-show="open" class="wrapper" :v-click-away="handleClickAway">
      <div class="backdrop"></div>
      <div :class="containerClasses">
        <div class="flex flex-row justify-between items-center px-8 pt-8">
          <slot name="title"></slot>
          <Button v-if="closeIcon" variant="flat" @click="handleClose">
            <span class="material-icons text-[28px]">close</span>
          </Button>
        </div>
        <div class="px-8 overflow-auto grow-1"><slot name="body"></slot></div>
        <div class="px-8 pb-8"><slot name="footer"></slot></div>
      </div>
    </div>
  </Transition>
</template>

<style scoped>
.wrapper {
  @apply fixed top-0 left-0 w-full h-full z-50;
}

.backdrop {
  @apply absolute w-full h-full bg-black bg-opacity-40;
}

.modal-container {
  /* generic style */
  @apply bg-white rounded-2xl shadow overflow-hidden absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col;

  max-height: calc(100vh - 48px);

  /* mobile size (< 720px) */
  width: calc(100vw - 48px);
  max-width: 768px;

  /* md size (>= 720px) */
  /* @apply md:w-[672px] md:max-w-[672px]; */

  /* lg size (>= 960px) */
  /* @apply lg:w-[896px] lg:max-w-[896px]; */

  /* xl size (>=1140px) */
  /* @apply xl:w-[1012px] xl:max-w-[1012px]; */
}

.modal-container.md {
  max-width: 512px;
}

.modal-container.sm {
  max-width: 512px;
}

.modal-container.xs {
  max-width: 376px;
}

.modal-container.fluid {
  width: fit-content;
}

/* styles for transition component */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.2s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
