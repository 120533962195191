<script setup>
import { defineProps, computed } from 'vue';
import Text from '../base/Text';

const props = defineProps({
  message: {
    type: String,
    default: null,
  },
  class: {
    type: String,
    default: '',
  },
});

const classes = computed(() => {
  return `h-4 text-red-600 text-center ${props.class}`;
});
</script>

<template>
  <Text variant="small" :class="classes">
    {{ props.message || '' }}
  </Text>
</template>

<style scoped></style>
