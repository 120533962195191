<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { ACTIONS, GETTERS } from '../store';
import { QUIZ_TYPE } from '../data/common';
import { QUIZ_TYPE_DATA, getQuizOrderFromKey, getQuizData, TOTAL_QUIZ } from '../data/quiz.js';
import ReadingQuiz from '../components/quiz/ReadingQuiz';
import IntroduceQuiz from '../components/quiz/IntroduceQuiz';
import CompareQuiz from '../components/quiz/CompareQuiz';
import QuizPage from '../components/quiz/QuizPage';
import { DATA_KEY } from '../constants';

const store = useStore();
const route = useRoute();

const quizComponent = computed(() => {
  const quizData = store?.getters[GETTERS.CURRENT_QUIZ_DATA];
  if (!quizData) return null;

  switch (quizData.type) {
    case QUIZ_TYPE.READING:
      return ReadingQuiz;
    case QUIZ_TYPE.OPEN_ENDED:
      return IntroduceQuiz;
    case QUIZ_TYPE.DESCRIBE:
      return CompareQuiz;
    default:
      return null;
  }
});

const quizKey = computed(() => {
  return route.params.quizKey;
});

const quizData = computed(() => {
  const school = store?.getters[GETTERS.SCHOOL];
  return getQuizData(school[DATA_KEY.TEST_ID])[quizKey.value];
});

const subtitle = computed(() => {
  const quizOrder = getQuizOrderFromKey(quizKey.value);
  return `ข้อที่ ${quizOrder}/${TOTAL_QUIZ}`;
});

const title = computed(() => {
  const quizType = quizData.value.type;
  return QUIZ_TYPE_DATA[quizType].title;
});

const instructions = computed(() => {
  const quizType = quizData.value.type;
  return QUIZ_TYPE_DATA[quizType].prompt;
});

const canRenderComponent = computed(() => {
  return !!quizComponent.value && !!quizData.value;
});

const isLastQuiz = computed(() => {
  const quizOrder = getQuizOrderFromKey(quizKey.value);
  return quizOrder == TOTAL_QUIZ;
});

const timeLimit = computed(() => {
  return quizData.value.timeLimit;
});

const preRecordingTime = computed(() => {
  return quizData.value.preRecordingTime;
});

const handleNext = async () => {
  await store.dispatch(ACTIONS.NEXT_QUIZ);
};
</script>

<template>
  <QuizPage
    v-if="canRenderComponent"
    :title="title"
    :subtitle="subtitle"
    :quizKey="quizKey"
    :instructions="instructions"
    :onNext="handleNext"
    :isLastQuiz="isLastQuiz"
    :timeLimit="timeLimit"
    :preRecordingTime="preRecordingTime"
  >
    <component :is="quizComponent" :quizData="quizData"></component>
  </QuizPage>
</template>
