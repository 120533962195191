<!--
  Input Component
  - Props:
    - leftAornment, rightAdornment: Allow input component to have icon or any adornments on the left and right side. See ADORNMENT_TYPES for possible adornments.

  - When adding a new type of adornment, always add to ADORNMENT_TYPES constant and left/rightAdornment props validator.
-->

<script setup>
import { computed, defineProps } from 'vue';

const ADORNMENT_TYPES = {
  SEARCH: 'search',
  CALENDAR: 'calendar',
};

const props = defineProps({
  readOnly: {
    type: Boolean,
    default: false,
  },
  rightAdornment: {
    type: String,
    validator: (value) => {
      return value.match(/(search|calendar)/); // Add validators when adding more adornment types
    },
  },
  leftAdornment: {
    type: String,
    validator: (value) => {
      return value.match(/(search|calendar)/);
    },
  },
  class: {
    type: String,
    default: '',
  },
});

const getAdornmentTemplate = (adornment) => {
  switch (adornment) {
    case ADORNMENT_TYPES.SEARCH:
      return `<span class="material-icons text-[18px] text-grey-600"> search </span>`;
    case ADORNMENT_TYPES.CALENDAR:
      return `<span class="material-icons text-[18px] text-grey-600"> calendar_today </span>`;
    default:
      return '';
  }
};

const classes = computed(
  () =>
    `rounded-full border border-primary flex px-5 py-3 font-semibold leading-[23.2px] ${props.class}`
);
</script>

<template>
  <div :class="classes">
    <div
      v-if="!!props.leftAdornment"
      v-html="getAdornmentTemplate(props.leftAdornment)"
      class="adornment left flex mr-2 p-[3px]"
    ></div>

    <component
      :is="readOnly ? 'div' : 'input'"
      class="placeholder-grey-300 grow text-paragraph-medium text-primary bg-transparent font-medium leading-[23.2px] w-full"
      v-bind="$attrs"
    ></component>

    <div
      v-if="!!props.rightAdornment"
      v-html="getAdornmentTemplate(props.rightAdornment)"
      class="adornment right flex ml-2 p-[3px]"
    ></div>
  </div>
</template>

<style scoped>
input:focus {
  outline: none;
}
.adornment {
  width: 24px;
  height: 24px;
}
</style>
