<!--
  Heading Component
  - Props:
    - variant: Used to specify the styles of the Heading.
    - is: Used to specify the rendered HTML tag for the component.

  - "variant" and "is" can be different to support the use case, such as when the role of the Heading is h1, but want to use the style of h2
-->

<script setup>
import { computed, defineProps } from 'vue';
const props = defineProps({
  variant: {
    type: String,
    required: true,
    validator: (value) => {
      return value.match(/(h1|h2|h3|h4|h5|h6)/);
    },
  },
  is: {
    type: String,
    validator: (value) => {
      return value.match(/(h1|h2|h3|h4|h5|h6|p|span|th)/);
    },
  },
  class: {
    type: String,
    default: '',
  },
});

const classes = computed(() => `${props.variant} ${props.class}`);
const is = computed(() => (props.is ? props.is : props.variant));
</script>

<template>
  <component :class="classes" :is="is" v-bind="$attrs">
    <slot></slot>
  </component>
</template>

<style scoped>
h1,
h2,
h3,
h4,
h5,
h6 {
  @apply text-grey-900 normal-case mb-0 font-semibold;
}
.h1 {
  @apply text-5xl;
}
.h2 {
  @apply text-4xl;
}
.h3 {
  @apply text-3xl;
}
.h4 {
  @apply text-2xl;
}
.h5 {
  @apply text-xl;
}
.h6 {
  @apply text-lg;
}
</style>
