<!--
  Text Component
  - Props:
    - variant: Used to specify the styles of the Paragraph.
-->

<script setup>
import { computed, defineProps } from 'vue';
const props = defineProps({
  variant: {
    type: String,
    default: 'normal',
    validator: (value) => {
      return value.match(/(xLarge|large|normal|small|tiny)/);
    },
  },
  is: {
    type: String,
    default: 'div',
  },
  class: {
    type: String,
    default: '',
  },
});

const classes = computed(() => `text ${props.variant} ${props.class}`);
</script>

<template>
  <component :class="classes" :is="is" v-bind="$attrs">
    <slot></slot>
  </component>
</template>

<style scoped>
.text {
  @apply text-grey-900 font-normal;
}
.large {
  @apply text-xl;
}
.medium {
  @apply text-lg;
}
.normal {
  @apply text-base;
}
.small {
  @apply text-sm;
}
.tiny {
  @apply text-xs;
}
</style>
