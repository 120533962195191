<script setup>
import { reactive, defineEmits, defineProps, watch } from 'vue';
import Button from '../../components/base/Button.vue';
import Input from '../../components/form/Input.vue';
import ErrorMessage from '../../components/form/ErrorMessage.vue';

// TODO: Check validation requirement
const FIELDNAME = ['prefix', 'firstname', 'lastname', 'school', 'grade', 'room'];
const PREFIX_OPTIONS = [
  { value: 'ด.ช.', text: 'ด.ช.' },
  { value: 'ด.ญ.', text: 'ด.ญ.' },
  { value: 'นาย', text: 'นาย' },
  { value: 'นางสาว', text: 'นางสาว' },
];

const GRADE_OPTIONS = [
  { value: 'Kindergarten (อนุบาล)', text: 'Kindergarten (อนุบาล)' },
  { value: 'Grade 1 (ป.1)', text: 'Grade 1 (ป.1)' },
  { value: 'Grade 2 (ป.2)', text: 'Grade 2 (ป.2)' },
  { value: 'Grade 3 (ป.3)', text: 'Grade 3 (ป.3)' },
  { value: 'Grade 4 (ป.4)', text: 'Grade 4 (ป.4)' },
  { value: 'Grade 5 (ป.5)', text: 'Grade 5 (ป.5)' },
  { value: 'Grade 6 (ป.6)', text: 'Grade 6 (ป.6)' },
  { value: 'Grade 7 (ม.1)', text: 'Grade 7 (ม.1)' },
  { value: 'Grade 8 (ม.2)', text: 'Grade 8 (ม.2)' },
  { value: 'Grade 9 (ม.3)', text: 'Grade 9 (ม.3)' },
  { value: 'Grade 10 (ม.4)', text: 'Grade 10 (ม.4)' },
  { value: 'Grade 11 (ม.5)', text: 'Grade 11 (ม.5)' },
  { value: 'Grade 12 (ม.6)', text: 'Grade 12 (ม.6)' },
  { value: 'College (มหาวิทยาลัย)', text: 'College (มหาวิทยาลัย)' },
];

const props = defineProps(['schoolName']);

const state = reactive({
  prefix: '',
  firstname: '',
  lastname: '',
  school: props.schoolName,
  grade: '',
  room: '',
  formIsValid: false,
  showErrorMessage: false,
});

const emit = defineEmits(['saveData']);

watch(
  () => state.prefix,
  () => {
    validateForm();
  }
);

watch(
  () => state.grade,
  () => {
    validateForm();
  }
);

const validateForm = () => {
  const isValid = FIELDNAME.every((field) => {
    return state[field].length > 0;
  });
  state.showErrorMessage = !isValid;
  state.formIsValid = isValid;
  return isValid;
};

const handleSubmit = () => {
  validateForm();
  if (state.formIsValid) {
    const formData = {
      prefix: state.prefix,
      firstname: state.firstname,
      lastname: state.lastname,
      fullname: `${state.prefix} ${state.firstname} ${state.lastname}`,
      school: state.school,
      grade: state.grade,
      room: state.room,
    };
    emit('saveData', formData);
  }
};

const handleChange = (e) => {
  state[e.target.id] = e.target.value;
  validateForm();
};
</script>

<template>
  <div class="flex gap-4 mb-2">
    <div class="dropdown-button w-[180px]">
      <select
        v-model="state.prefix"
        name="prefix"
        id="prefix"
        class="w-full cursor-pointer h-full pl-5 rounded-full outline-0"
      >
        <option value="" disabled selected>คำนำหน้า</option>
        <option v-for="prefix in PREFIX_OPTIONS" v-bind:key="prefix.text" :value="prefix.value">
          {{ prefix.text }}
        </option>
      </select>
    </div>
    <Input
      id="firstname"
      class="mb-4"
      :value="state.firstname"
      @input="handleChange"
      placeholder="ชื่อ"
    />
    <Input
      id="lastname"
      class="mb-4"
      :value="state.lastname"
      @input="handleChange"
      placeholder="นามสกุล"
    />
  </div>
  <div class="flex gap-4 mb-10">
    <div class="dropdown-button w-[180px] flex-grow">
      <select
        v-model="state.grade"
        name="grade"
        id="grade"
        class="w-full cursor-pointer h-full pl-5 rounded-full outline-0"
      >
        <option value="" disabled selected>ชั้นเรียน</option>
        <option v-for="grade in GRADE_OPTIONS" v-bind:key="grade.text" :value="grade.value">
          {{ grade.text }}
        </option>
      </select>
    </div>
    <Input
      id="room"
      class="flex-grow"
      :value="state.room"
      type="number"
      @input="handleChange"
      placeholder="ห้องเรียน (กรุณากรอกเฉพาะตัวเลข)"
    />
  </div>
  <div class="w-full flex justify-center mb-4">
    <Button size="large" v-on:click="handleSubmit" :disabled="!state.formIsValid">
      ลงทะเบียน
    </Button>
  </div>
  <ErrorMessage :message="state.showErrorMessage && 'กรุณากรอกข้อมูลให้ครบทุกช่อง'" />
</template>

<style scoped>
.dropdown-button {
  @apply rounded-full border flex pr-5 font-semibold border-primary text-grey-900 items-center justify-between cursor-pointer h-[50px];
}
</style>
