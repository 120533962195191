<script setup>
import { onMounted, computed, reactive } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { ROUTES } from './router.js';
import { ACTIONS, GETTERS } from './store';
import { QUIZ_KEY, DATA_KEY, CODE_TYPE } from './constants';
import AppHeader from './components/layout/AppHeader.vue';
import AppContainer from './components/layout/AppContainer.vue';
import ContinueSessionModal from './components/modals/ContinueSessionModal';
import StartOverModal from './components/modals/StartOverModal';
import HomeFooter from './components/layout/HomeFooter.vue';

const router = useRouter();
const route = useRoute();
const store = useStore();
const state = reactive({
  continueSessionModalOpen: false,
});

onMounted(async () => {
  const hasExistingSession = await store.dispatch(ACTIONS.CHECK_EXISTING_SESSION);
  const quizStatus = store.getters[GETTERS.QUIZ_STATUS];
  const hasFinishedAllQuizzes = Object.values(QUIZ_KEY).every((key) => quizStatus[key]);

  /**
   * We want users to be able to click back to finish page in case they forgot to note the contact.
   * Therefore, we won't redirect users if they are on Finish page
   */
  const isFinishPage = window.location.pathname === ROUTES.FINISH.path;

  /**
   * TODO: Remove this after testing Mic
   */
  if (window.location.pathname === ROUTES.MIC_TEST.path) {
    return;
  }

  if (hasExistingSession && !hasFinishedAllQuizzes) {
    state.continueSessionModalOpen = true;
  } else if (!isFinishPage) {
    startOver();
  }
});

const startOver = () => {
  store.dispatch(ACTIONS.END_SESSION);
  router.push(ROUTES.HOME.path);
};

const handleStartOver = () => {
  startOver();
  state.startOverModalOpen = false;
};

const handleOpenStartOverModal = () => {
  state.startOverModalOpen = true;
};

const handleCloseStartOverModal = () => {
  state.startOverModalOpen = false;
};

const handleContinueExistingSession = () => {
  const quizKey = store.getters[GETTERS.CURRENT_QUIZ_KEY];
  const path = ROUTES.QUIZ.getRoute(quizKey);
  if (quizKey) {
    router.push(path);
  } else {
    router.push(ROUTES.INSTRUCTION.path);
  }
  state.continueSessionModalOpen = false;
};

const handleDeclineExistingSession = () => {
  startOver();
  state.continueSessionModalOpen = false;
};

const isHome = computed(() => {
  return route.path === '/';
});

const hasSession = computed(() => {
  const sessionKey = store?.getters[GETTERS.SESSION_KEY];
  return !!sessionKey;
});

const username = computed(() => {
  const student = store?.getters[GETTERS.STUDENT];
  const name = student ? student[DATA_KEY.STUDENT_NAME] : null;
  return name ? name.split(' ')[0] : null;
});

const fullUsername = computed(() => {
  const student = store?.getters[GETTERS.STUDENT];
  const name = student ? student[DATA_KEY.STUDENT_NAME] : null;
  return name ? name : null;
});

const schoolName = computed(() => {
  const school = store?.getters[GETTERS.SCHOOL];
  const name = school ? school[DATA_KEY.SCHOOL_NAME] : null;
  return name ? name : null;
});

const isTeacherSession = computed(() => {
  const school = store?.getters[GETTERS.SCHOOL];
  const codeType = school ? school[DATA_KEY.CODE_TYPE] : null;
  return codeType === CODE_TYPE.TEACHER;
});

const currentQuizKey = computed(() => {
  return store?.getters[GETTERS.CURRENT_QUIZ_KEY];
});
</script>

<template>
  <AppHeader
    :onStartOver="handleOpenStartOverModal"
    :hasSession="hasSession"
    :username="username"
    :schoolName="schoolName"
  ></AppHeader>
  <AppContainer>
    <router-view v-bind:key="route.fullPath"></router-view>
  </AppContainer>
  <HomeFooter v-if="isHome" />
  <ContinueSessionModal
    :open="state.continueSessionModalOpen"
    :username="fullUsername"
    :schoolName="schoolName"
    :currentQuiz="currentQuizKey"
    :isTeacherSession="isTeacherSession"
    :onConfirm="handleContinueExistingSession"
    :onCancel="handleDeclineExistingSession"
  />
  <StartOverModal
    :open="state.startOverModalOpen"
    :onConfirm="handleStartOver"
    :onCancel="handleCloseStartOverModal"
    :onClose="handleCloseStartOverModal"
  />
</template>

<style>
body {
  background-image: url('./assets/images/bg.png');
}

html {
  font-family: 'Prompt';
}

.font-brand {
  font-family: 'Baloo Chettan 2';
}

.font-lato {
  font-family: Lato;
}

.font-prompt {
  font-family: Prompt;
}

.top-banner-section {
  height: 405px;
}
@screen sm {
  .top-banner-section {
    height: 500px;
  }
}
@screen xl {
  .top-banner-section {
    height: 640px;
  }
}
</style>
