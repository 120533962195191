import { QUIZ_KEY } from "../constants";
import { QUIZ_TYPE } from "./common";

export const QUIZ_DATA_SETB = {
    [QUIZ_KEY.QUIZ_1]: {
      index: 1,
      type: QUIZ_TYPE.READING,
      name: 'แบบทดสอบการอ่านออกเสียง',
      prompt: [
        "My favorite subject is English.",
        "I play basketball, tennis, and football.",
        "My mom and I have a pet rabbit.",
        "The vase has a plant in it.",
        "The bell always rings at five.",
        "My favorite food is in my backpack.",
        "The cat is playing with a red ball.",
        "I love to read books at the library.",
        "The van stops to pick up the students.",
        "The kitten stays on my lap when I study."
      ],
      timeLimit: 60,
      preRecordingTime: 60,
    },
    [QUIZ_KEY.QUIZ_2]: {
      index: 2,
      type: QUIZ_TYPE.READING,
      name: 'แบบทดสอบการอ่านออกเสียง',
      prompt: [
        "He had to push hard to fasten his belt.",
        "She had a thin slice of cheese on her sandwich.",
        "She should choose the fresh fish for breakfast.",
        "The ship caught five fish in the North Sea.",
        "I had to choose between the thin and thick socks.",
        "I think we should try the free sample of fried chicken.",
        "His teeth felt smooth after he used the new toothbrush.",
        "A cheerful smile can improve communication and spread happiness.",
        "They will have a huge advantage with the advantageous job offer.",
        "The journalist had a difficult time catching up with the scientist.",
        "The chair was difficult to move because of the thick carpet.",
        "The smooth cheese melted in their mouths as they took a bite."
      ],
      timeLimit: 60,
      preRecordingTime: 60,
    },
    [QUIZ_KEY.QUIZ_3]: {
      index: 3,
      type: QUIZ_TYPE.DESCRIBE,
        name: 'แบบทดสอบการอธิบาย',
      prompt: [
        "Find the differences in the pictures.",
        "Look at both pictures and tell me how your picture is different from my picture.",
        "-----",
        'Prompt: “In my picture, there is _______."',
        ""
      ],
      imagePromptUrl: '/assets/images/umbrella.png',
      timeLimit: 60,
      preRecordingTime: 60,
    },
    [QUIZ_KEY.QUIZ_4]: {
      index: 4,
      type: QUIZ_TYPE.OPEN_ENDED,
      name: 'แบบทดสอบการพูด',
      prompt: [
        "What’s your favorite subject in school? Why? Can you tell us about some of the things you learn in that class? ",
        "-----",
      ],
      timeLimit: 30,
      preRecordingTime: 60,
    },
    [QUIZ_KEY.QUIZ_5]: {
      index: 5,
      type: QUIZ_TYPE.OPEN_ENDED,
      name: 'แบบทดสอบการพูด',
      prompt: [
        "Do you prefer reading books or watching movies? Why? What is the name of your favorite book or movie? What is it about? Please tell me about it!",
        `-----`,
      ],
      timeLimit: 30,
      preRecordingTime: 60,
    },
  };