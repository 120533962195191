<script setup>
import { reactive, defineProps, watch, computed } from 'vue';
import Icon, { ICON_NAME } from '../../components/base/Icon';
import Text from '../../components/base/Text.vue';
import Heading from '../../components/base/Heading';

const SMOOTH_TIME_CONSTANT = 0.1;
const BACKGROUND_NOISE_TIME = 5;
const SPEAKING_NOISE_TIME = 15;

const RECORDING_STATUS = {
  NOT_STARTED: 'NOT_STARTED',
  PREPARING: 'PREPARING',
  RECORDING: 'RECORDING',
  STOPPING: 'STOPPING',
  UPLOADING: 'UPLOADING',
  FINISHED: 'FINISHED',
  ERROR: 'ERROR',
};

const INITIAL_STATE = {
  status: RECORDING_STATUS.NOT_STARTED,
  isRecordingBackgroundNoise: false,
  timerIntervalInstance: null,
  audioChunk: [],
  mediaRecorder: null,
  analyser: null,
  backgroundNoiseTimeLeft: BACKGROUND_NOISE_TIME,
  backgroundNoiseData: [],
  speakingNoiseTimeLeft: SPEAKING_NOISE_TIME,
  speakingNoiseData: [],
  audioUrl: null,
};

/** TODO: Activate this when api is ready */
// const store = useStore();
const props = defineProps({
  timeLimit: { type: Number },
  onFinish: { type: Function, default: () => {} },
  onTimeup: { type: Function, default: () => {} },
  onUploaded: { type: Function, default: () => {} },
  onStopRecording: { type: Function, default: () => {} },
  uploadRecordingFunc: { type: Function },
});
const state = reactive({ ...INITIAL_STATE });

/**
 * All recording events will be handle in watch based on status value.
 * Event handlers will only change the status to trigger this.
 */
watch(
  () => state.status,
  async (newStatus) => {
    switch (newStatus) {
      case RECORDING_STATUS.PREPARING:
        state.timeLeft = INITIAL_STATE.timeLeft;
        state.audioChunk = INITIAL_STATE.audioChunk;
        state.mediaRecorder = INITIAL_STATE.mediaRecorder;

        try {
          await setUpRecorder();
          state.status = RECORDING_STATUS.RECORDING;
        } catch (error) {
          console.error(error);
          state.status = RECORDING_STATUS.ERROR;
        }
        break;
      case RECORDING_STATUS.RECORDING:
        state.isRecordingBackgroundNoise = true;
        state.mediaRecorder.start(200);
        startTimer();
        break;
      case RECORDING_STATUS.STOPPING:
        if (state.timerIntervalInstance) {
          stopTimer();
        }
        state.mediaRecorder.stop();
        props.onStopRecording();
        state.status = RECORDING_STATUS.UPLOADING;
        break;
      case RECORDING_STATUS.UPLOADING:
        // Workaround: Set timeout to make sure all the audio data is available.
        setTimeout(async () => {
          try {
            await uploadRecording();
            await props.onUploaded();
            state.status = RECORDING_STATUS.FINISHED;
          } catch (error) {
            console.error(error);
            state.status = RECORDING_STATUS.ERROR;
          }
        }, 1000);
        break;
      case RECORDING_STATUS.FINISHED:
        state.isFinishedPopupOpen = true;
        props.onFinish();
        break;
      case RECORDING_STATUS.ERROR:
      default:
        alert('Something went wrong, please refresh the page and try again.');
    }
  }
);

/**
 * Call endpoint to upload the reecording.
 * TODO: Check filename pattern.
 */
const uploadRecording = async () => {
  const audioBlob = new Blob(state.audioChunk, { type: 'audio/wav' });
  const audioUrl = URL.createObjectURL(audioBlob);
  state.audioUrl = audioUrl;
  await props.uploadRecordingFunc(audioBlob, {
    bgNoise: avgBackgroundNoiseDB.value.toFixed(4),
    speakingNoise: avgSpeakingNoiseDB.value.toFixed(4),
    diff: (avgSpeakingNoiseDB.value - avgBackgroundNoiseDB.value).toFixed(4),
  });
};

function getStandardDeviation(array) {
  const n = array.length;
  const mean = array.reduce((a, b) => a + b, 0) / n;
  return Math.sqrt(array.map((x) => Math.pow(x - mean, 2)).reduce((a, b) => a + b, 0) / n);
}
/**
 * Setup mediaRecorder and its event listeners.
 * If success, mediaRecorder instance should be available in state.
 */
const setUpRecorder = async () => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    const mediaRecorder = new MediaRecorder(stream, { audioBitsPerSecond: 128000 });

    /**
     * Set up Audio Analyser
     */
    const context = new AudioContext();
    const source = context.createMediaStreamSource(mediaRecorder.stream);

    const analyser = context.createAnalyser();
    analyser.smoothingTimeConstant = SMOOTH_TIME_CONSTANT;
    source.connect(analyser);
    state.analyser = analyser;

    mediaRecorder.ondataavailable = async (e) => {
      const dataArray = new Uint8Array(analyser.frequencyBinCount);
      state.analyser.getByteFrequencyData(dataArray);

      let values = 1;
      for (var i = 0; i < dataArray.length; i++) {
        values += dataArray[i];
      }
      // const max = Math.max(Math.max(...dataArray), 1);
      // const average = 20 * Math.log10(values / dataArray.length);
      // const average = 20 * Math.log10(max);
      const average = values / dataArray.length;
      if (state.isRecordingBackgroundNoise) {
        state.backgroundNoiseData.push(average);
      } else {
        state.audioChunk.push(e.data);
        state.speakingNoiseData.push(average);
      }
    };
    state.mediaRecorder = mediaRecorder;
  } catch (error) {
    console.error(error);
    alert('Please allow record ', error);
  }
};

/**
 * Methods to control timer.
 * TODO: Check the precision of the timer. Might need to check with the actual datetime to calculate timeLeft.
 */
const startTimer = () => {
  state.isRecordingBackgroundNoise = true;
  state.backgroundNoiseTimeLeft = BACKGROUND_NOISE_TIME;
  state.speakingNoiseTimeLeft = SPEAKING_NOISE_TIME;

  state.timerIntervalInstance = setInterval(() => {
    if (state.backgroundNoiseTimeLeft > 0) {
      state.backgroundNoiseTimeLeft--;
    } else {
      state.isRecordingBackgroundNoise = false;
      state.speakingNoiseTimeLeft--;
    }

    if (state.backgroundNoiseTimeLeft <= 0 && state.speakingNoiseTimeLeft <= 0) {
      props.onTimeup();
      handleStopRecording();
    }
  }, 1000);
};

const stopTimer = () => {
  clearInterval(state.timerIntervalInstance);
  state.timerIntervalInstance = null;
};

/**
 * UI event handlers.
 */
const handleStartRecording = async () => {
  state.status = RECORDING_STATUS.PREPARING;
};

const handleStopRecording = async () => {
  state.status = RECORDING_STATUS.STOPPING;
};

const handleToggleRecording = () => {
  if (canStartRecording.value) {
    handleStartRecording();
    return; // return to stop function.
  }
  if (canStopRecording.value) {
    handleStopRecording();
    return;
  }
};

/**
 * Computed variables
 */
const canStartRecording = computed(() => {
  return state.status === RECORDING_STATUS.NOT_STARTED;
});
const canStopRecording = computed(() => {
  return state.status === RECORDING_STATUS.RECORDING;
});
const isRecording = computed(() => {
  return state.status === RECORDING_STATUS.RECORDING;
});
const avgBackgroundNoiseDB = computed(() => {
  const data = [...state.backgroundNoiseData];
  const avg = data.reduce((a, b) => a + b, 0) / data.length;
  const sd = getStandardDeviation(data);
  const filteredData = data.filter((d) => {
    return d >= avg - sd - sd && d <= avg + sd + sd;
  });

  const sortedData = filteredData.sort((a, b) => b - a);
  const count = Math.floor(sortedData.length / 3);
  const slicedData = sortedData.slice(0, count);

  const values = slicedData.reduce((a, b) => a + b, 0);
  return values / slicedData.length;
});
const avgSpeakingNoiseDB = computed(() => {
  const data = [...state.speakingNoiseData];
  const avg = data.reduce((a, b) => a + b, 0) / data.length;
  const sd = getStandardDeviation(data);
  const filteredData = data.filter((d) => {
    return d >= avg - sd - sd && d <= avg + sd + sd;
  });

  const sortedData = filteredData.sort((a, b) => b - a);
  const count = Math.floor(sortedData.length / 3);
  const slicedData = sortedData.slice(0, count);

  const values = slicedData.reduce((a, b) => a + b, 0);
  return values / slicedData.length;
});
</script>

<template>
  <div class="flex items-center gap-6 w-full justify-between">
    <div>
      <div class="flex items-center gap-4">
        <div>Avg background noise:</div>
        <Heading variant="h4">{{ avgBackgroundNoiseDB.toFixed(4) }}</Heading>
      </div>
      <div class="flex items-center gap-4">
        <div>Avg speaking noise:</div>
        <Heading variant="h4">{{ avgSpeakingNoiseDB.toFixed(4) }}</Heading>
      </div>
      <div class="flex items-center gap-4">
        <div>Diff:</div>
        <Heading variant="h4">{{ (avgSpeakingNoiseDB - avgBackgroundNoiseDB).toFixed(4) }}</Heading>
      </div>
    </div>
    <div
      class="button-container flex items-center gap-3 rounded-full pl-10 pr-7 py-4 max-w-xs"
      style="height: fit-content"
      v-on:click="handleToggleRecording"
      :class="isRecording ? 'bg-red-700' : 'bg-white'"
    >
      <div class="flex-grow">
        <div v-if="isRecording && state.isRecordingBackgroundNoise">
          <Text variant="tiny" class="text-right text-white">
            กำลังบันทึกเสียงสภาพแวดล้อม {{ state.backgroundNoiseTimeLeft }}<br />
          </Text>
          <Text class="text-right text-white">
            กรุณาอยู่ในความเงียบ
          </Text>
        </div>
        <div v-if="isRecording && !state.isRecordingBackgroundNoise">
          <Text variant="tiny" class="text-right text-white"> กำลังบันทึกเสียง... <br /> </Text>
          <Text class="text-right text-white">
            เหลือเวลาอีก {{ state.speakingNoiseTimeLeft }}
          </Text>
        </div>
        <Text v-if="canStartRecording" variant="small" class="text-right">
          กดปุ่มเพื่อเริ่มบันทึกเสียง และกดอีกครั้งเพื่อจบการบันทึกเสียง
        </Text>
        <!-- Check this behaviour -->
        <Text v-if="state.status === RECORDING_STATUS.FINISHED">บันทึกเสียงเสร็จสิ้น</Text>
      </div>

      <div class="icon-container flex-shrink-0" v-bind="$attrs">
        <div v-if="canStartRecording" v-on:click="handleStartRecording" class="w-full h-full">
          <Icon :iconName="ICON_NAME.MICROPHONE" class="button-icon text-red-500" />
        </div>
        <div v-if="canStopRecording" v-on:click="handleStopRecording">
          <Icon :iconName="ICON_NAME.STOP" class="button-icon text-white" />
        </div>
      </div>
    </div>
    <audio v-if="state.audioUrl" controls>
      <source :src="state.audioUrl" type="audio/mp3" />
    </audio>
  </div>
</template>

<style scoped>
.button-container {
  @apply cursor-pointer shadow-md;
}
.button-container:hover {
  @apply cursor-pointer shadow-lg;
}
.button-container:active {
  @apply cursor-pointer shadow-sm;
}

.icon-container {
  width: 40px;
  height: 40px;
}
.button-icon {
  width: 100%;
  height: 100%;
}
</style>
