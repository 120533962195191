import { DATA_KEY, TESTER_DATA_KEY } from "../constants";
const BASE_URL = 'https://edsy-assess-backend-vg5zhtok2a-as.a.run.app';

const ERRORS = {
  UNKONWN: "UNKNOWN",
};

const fetch2 = async (fetchUrl, requestOptions) => {
  try {
    const rawResponse = await fetch(fetchUrl, requestOptions);
    const response = await rawResponse.json();
    if (response.success) {
      return response.data;
    } else {
      const error = new Error();
      error.code = response.errorCode;
      error.message = response.errorMessage;
      throw error;
    }
  } catch (err) {
    const error = new Error();
    error.code = ERRORS.UNKONWN;
    error.message = "Something went wrong";
    throw error;
  }
};

const getRequest = async (fetchUrl, requestOptions) => {
  const options = {
    method: "GET",
    ...requestOptions,
  };
  return fetch2(fetchUrl, options);
};

const postRequest = async (fetchUrl, requestOptions) => {
  const options = {
    method: "POST",
    ...requestOptions,
  };
  return fetch2(fetchUrl, options);
};

export const uploadRecording = async (audioBlob, fileName) => {
  try {
    const formdata = new FormData();
    formdata.append("file", audioBlob, `${fileName}.wav`);
    const fetchUrl = new URL("upload", BASE_URL);

    const data = await postRequest(fetchUrl, {
      body: formdata,
      headers: new Headers({
        enctype: "multipart/form-data",
      }),
    });
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// TODO: Remove this test
export const uploadRecordingTestMic = async (audioBlob, fileName) => {
  try {
    const formdata = new FormData();
    formdata.append("file", audioBlob, `${fileName}.wav`);
    const fetchUrl = new URL("upload-test", BASE_URL);

    const data = await postRequest(fetchUrl, {
      body: formdata,
      headers: new Headers({
        enctype: "multipart/form-data",
      }),
    });
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const register = async (data) => {
  const registerData = {
    prefix: data[DATA_KEY.STUDENT_PREFIX],
    firstname: data[DATA_KEY.STUDENT_FIRSTNAME],
    lastname: data[DATA_KEY.STUDENT_LASTNAME],
    name: data[DATA_KEY.STUDENT_NAME],
    grade: data[DATA_KEY.STUDENT_GRADE],
    room: data[DATA_KEY.STUDENT_ROOM],
    code: data[DATA_KEY.SCHOOL_CODE],
    school: data[DATA_KEY.SCHOOL_NAME],
    email: data[TESTER_DATA_KEY.TESTER_EMAIL],
    phoneNo: data[TESTER_DATA_KEY.TESTER_PHONE_NO],
  };
  try {
    const fetchUrl = new URL("register", BASE_URL);
    const options = {
      body: JSON.stringify(registerData),
      headers: {
        "Content-Type": "application/json",
      },
    };
    console.log(registerData)
    const data = await postRequest(fetchUrl, options);
    return data.key;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const validateSchoolCode = async (code) => {
  try {
    const fetchUrl = new URL("check-code", BASE_URL);
    fetchUrl.search = new URLSearchParams({ code }).toString();
    const data = await getRequest(fetchUrl);
    return data; // Expect {name, type}
  } catch (error) {
    console.error(error);
    throw error;
  }
};
