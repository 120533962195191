export const RECORDING_STATUS = {
  NOT_STARTED: 'NOT_STARTED',
  PRE_RECORDING: 'PRE_RECORDING', // For running preRecording timer
  PREPARING: 'PREPARING',
  RECORDING: 'RECORDING',
  STOPPING: 'STOPPING',
  UPLOADING: 'UPLOADING',
  FINISHED: 'FINISHED',
  ERROR: 'ERROR',
};
