<script setup>
import { reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { ACTIONS } from '../store';
import { ROUTES } from '../router';
import Card from '../components/base/Card';
import Heading from '../components/base/Heading';
import Button from '../components/base/Button';
import Input from '../components/form/Input';
import ErrorMessage from '../components/form/ErrorMessage';
import LoadingModal from '../components/modals/LoadingModal';

const router = useRouter();
const store = useStore();

const ERROR_MESSAGE = {
  INVALID_CODE: 'ไม่พบโค้ดในระบบ กรุณาตรวจสอบโค้ดอีกครั้ง',
  BLANK_VALUE: 'กรุณากรอกโค้ด',
};

const state = reactive({
  code: '',
  errorMessage: null,
  loading: false,
});

const handleValidateCode = async () => {
  state.loading = true;
  try {
    await store.dispatch(ACTIONS.REGISTER_SCHOOL, { schoolCode: state.code });
    router.push(ROUTES.REGISTRATION.path);
  } catch (error) {
    state.errorMessage = ERROR_MESSAGE.INVALID_CODE;
  }
  state.loading = false;
};

const handleChange = (e) => {
  const value = e.target.value;
  state.code = value;
  if (!state.code) {
    state.errorMessage = ERROR_MESSAGE.BLANK_VALUE;
  } else {
    state.errorMessage = null;
  }
};
</script>
<template>
  <Card class="p-10">
    <Heading variant="h2" class="text-primary mb-4 text-center">
      แบบทดสอบ EdSpeak by Edsy
    </Heading>
    <Heading variant="h3" class="text-grey-600 mb-12 text-center">
      วัดระดับทักษะการพูดภาษาอังกฤษ
    </Heading>

    <div class="flex flex-col items-center px-12">
      <Input
        id="code"
        class="w-full mb-6"
        :value="state.room"
        @input="handleChange"
        placeholder="กรุณากรอกโค้ด"
      />
      <Button
        v-on:click="handleValidateCode"
        :disabled="!state.code"
        size="large"
        class="w-48 mb-4"
      >
        ยืนยัน
      </Button>
      <ErrorMessage :message="state.errorMessage" />
    </div>
  </Card>
  <LoadingModal :open="state.loading" text="กำลังตรวจสอบโค้ด" />
</template>
