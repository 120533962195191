<script setup>
import { defineProps } from 'vue';
import Button from '../base/Button';
import Text from '../base/Text';

const props = defineProps({
  onStartOver: {
    type: Function,
    default: () => {},
  },
  username: {
    type: String,
    default: '',
  },
  schoolName: {
    type: String,
    default: '',
  },
  hasSession: {
    type: Boolean,
  },
});

const handleClick = () => {
  props.onStartOver();
};
</script>

<template>
  <header class="bg-white shadow ">
    <div
      class="container app-header-container flex flex-col sm:flex-row items-start sm:items-center justify-between mx-auto px-4 lg:px-8 py-5 gap-4"
    >
      <router-link to="/">
        <img src="/assets/images/brand.png" class="object-contain h-5" />
      </router-link>
      <div v-if="props.hasSession" class="flex flex-row items-center self-end sm:self-auto">
        <div class="user-info-container flex flex-col items-end pr-3 mr-3">
          <Text variant="tiny" class="user-info-text">{{ props.schoolName }}</Text>
          <Text class="user-info-text font-semibold ">{{ props.username }}</Text>
        </div>
        <Button variant="outlined" size="small" :onClick="handleClick">เริ่มทำแบบทดสอบใหม่</Button>
      </div>
    </div>
  </header>
</template>

<style scoped>
.app-header-container {
  min-height: 80px;
}
.user-info-container {
  border-right: 1px solid grey;
}
.user-info-text {
  @apply text-primary text-right;
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
