<script setup>
import { reactive } from 'vue';
// import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { ACTIONS } from '../../store';
// import { ROUTES } from '../../router';
import Card from '../../components/base/Card';
import Heading from '../../components/base/Heading';
import Text from '../../components/base/Text';
import LoadingModal from '../../components/modals/LoadingModal';
import TestRecordingSection from './TestRecordingSection';

// const router = useRouter();
const store = useStore();

const state = reactive({
  code: '',
  errorMessage: null,
  loading: false,
  audioObj: null,
  audioUrl: null,
});

const handleStopRecording = () => {
  state.loading = true;
};

const handleRecordingUploaded = () => {
  state.loading = false;
};

const uploadRecording = async (audioBlob, { bgNoise, speakingNoise, diff }, key) => {
  const audioUrl = URL.createObjectURL(audioBlob);
  state.audioUrl = audioUrl;
  store.dispatch(ACTIONS.SUBMIT_QUIZ_TEST, {
    audioBlob,
    quizKey: key,
    bgNoise,
    speakingNoise,
    diff,
  });
};
</script>
<template>
  <Card class="p-10 mb-6">
    <div>
      <Text is="p" variant="large">
        I am 7 years old and I love watching cartoons. My favorite cartoons are Moana and Zootopia.
        Moana is about a girl who goes on a big adventure. Zootopia is about a bunny police officer.
        <br />
        I watch cartoons on TV or on my tablet. I watch them when I have free time, like on weekends
        or after school. My brothers like to watch Disney cartoons too, so we always watch them
        together.
      </Text>
    </div>
  </Card>
  <Card class="p-10 mb-6">
    <Heading variant="h2" class="mb-4">Quiz 1</Heading>
    <TestRecordingSection
      :uploadRecordingFunc="(blob, data) => uploadRecording(blob, data, 'quiz1')"
      :onUploaded="handleRecordingUploaded"
      :onStopRecording="handleStopRecording"
    />
  </Card>
  <Card class="p-10 mb-6">
    <Heading variant="h2" class="mb-4">Quiz 2</Heading>
    <TestRecordingSection
      :uploadRecordingFunc="(blob, data) => uploadRecording(blob, data, 'quiz2')"
      :onUploaded="handleRecordingUploaded"
      :onStopRecording="handleStopRecording"
    />
  </Card>
  <Card class="p-10 mb-6">
    <Heading variant="h2" class="mb-4">Quiz 3</Heading>
    <TestRecordingSection
      :uploadRecordingFunc="(blob, data) => uploadRecording(blob, data, 'quiz3')"
      :onUploaded="handleRecordingUploaded"
      :onStopRecording="handleStopRecording"
    />
  </Card>
  <Card class="p-10 mb-6">
    <Heading variant="h2" class="mb-4">Quiz 4</Heading>
    <TestRecordingSection
      :uploadRecordingFunc="(blob, data) => uploadRecording(blob, data, 'quiz4')"
      :onUploaded="handleRecordingUploaded"
      :onStopRecording="handleStopRecording"
    />
  </Card>
  <Card class="p-10 mb-6">
    <Heading variant="h2" class="mb-4">Quiz 5</Heading>
    <TestRecordingSection
      :uploadRecordingFunc="(blob, data) => uploadRecording(blob, data, 'quiz5')"
      :onUploaded="handleRecordingUploaded"
      :onStopRecording="handleStopRecording"
    />
  </Card>
  <Card class="p-10 mb-6">
    <Heading variant="h2" class="mb-4">Quiz 6</Heading>
    <TestRecordingSection
      :uploadRecordingFunc="(blob, data) => uploadRecording(blob, data, 'quiz6')"
      :onUploaded="handleRecordingUploaded"
      :onStopRecording="handleStopRecording"
    />
  </Card>
  <LoadingModal :open="state.loading" text="กำลังโหลด" />
</template>
