<!-- Icon Component -->

<script setup>
import { computed, defineProps } from 'vue';
import MicrophoneIcon from 'vue-material-design-icons/Microphone.vue';
import StopIcon from 'vue-material-design-icons/Stop.vue';
import { ICON_NAME } from './icons.js';

const props = defineProps({
  iconName: {
    type: String,
  },
  class: {
    type: String,
    default: '',
  },
});

const iconComponent = computed(() => {
  switch (props.iconName) {
    case ICON_NAME.MICROPHONE:
      return MicrophoneIcon;
    case ICON_NAME.STOP:
      return StopIcon;
    default:
      return 'div';
  }
});

const classes = computed(() => {
  return `icon-container ${props.class}`;
});
</script>

<template>
  <div :class="classes" v-bind="$attrs">
    <component :is="iconComponent" class="icon-component" />
  </div>
</template>

<style scoped>
.icon-container {
  @apply aspect-w-1 aspect-h-1;
  min-width: 24px;
  min-height: 24px;
}
</style>

<style>
/* These styles are applied to nested component from third party and make it cannot be in scoped */
.material-design-icon.icon-component {
  height: 100%;
  width: 100%;
}

.material-design-icon.icon-component > .material-design-icon__svg {
  height: 100%;
  width: 100%;
}
</style>
