import mutations from './mutations.js';
import actions, { ACTIONS } from './actions.js';
import getters, { GETTERS } from './getters.js';
import state from './state.js';

const SESSION_ACTIONS = {};
Object.keys(ACTIONS).forEach((key) => {
  SESSION_ACTIONS[key] = `session/${ACTIONS[key]}`;
});

const SESSION_GETTERS = {};
Object.keys(GETTERS).forEach((key) => {
  SESSION_GETTERS[key] = `session/${GETTERS[key]}`;
});

export { SESSION_ACTIONS, SESSION_GETTERS };
export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
