import { QUIZ_KEY } from '../constants';
import { QUIZ_TYPE } from './common';
import { QUIZ_DATA_SETA } from './seta';
import { QUIZ_DATA_SETB } from './setb';
import { QUIZ_DATA_SETC } from './setc';
import { QUIZ_DATA_SETD } from './setd';

export const TOTAL_QUIZ = 5;

export const QUIZ_TYPE_DATA = {
  [QUIZ_TYPE.READING]: {
    title: 'PART 1: Reading Questions',
    prompt: [
      'Please read the below script out loud (1 minute). You have 1 minute to read the question then the recording will start automatically.',
      'อ่านข้อความด้านล่าง ความยาวเวลา 1 นาที คุณมีเวลา 1 นาทีในการอ่านคำถาม หลังจากนั้นระบบจะเริ่มอัดเสียงโดยอัตโนมัติ',
    ],
  },
  [QUIZ_TYPE.OPEN_ENDED]: {
    title: 'PART 2: Open-ended Questions',
    prompt: [
      'Please answer the question below out loud (30 seconds). You have 1 minute to read the question then the recording will start automatically.',
      'ตอบคำถามด้านล่าง ความยาวเวลา 30 วินาที คุณมีเวลา 1 ินาทีในการอ่านคำถาม หลังจากนั้นระบบจะเริ่มอัดเสียงโดยอัตโนมัติ',
    ],
  },
  [QUIZ_TYPE.DESCRIBE]: {
    title: 'Part 3: Describe Questions',
    prompt: [
      'Please describe the picture below for 1 minute. You have 1 minute to read the question then the recording will start automatically.',
      'อธิบายรูปด้านล่าง ความยาวเวลา 1 นาที คุณมีเวลา 1 นาทีในการอ่านคำถาม หลังจากนั้นระบบจะเริ่มอัดเสียงโดยอัตโนมัติ',
    ],
  },
};

export const getQuizData = (testId) => {
  if (testId === "TEST-A") {
    return QUIZ_DATA_SETA;
  } else if (testId === "TEST-B") {
    return QUIZ_DATA_SETB;
  } else if (testId === "TEST-C") {
    return QUIZ_DATA_SETC;
  } else if (testId === "TEST-D") {
    return QUIZ_DATA_SETD;
  } else {
    return QUIZ_DATA_SETA
  }
}

export const QUIZ_ORDER = {
  1: QUIZ_KEY.QUIZ_1,
  2: QUIZ_KEY.QUIZ_2,
  3: QUIZ_KEY.QUIZ_3,
  4: QUIZ_KEY.QUIZ_4,
  5: QUIZ_KEY.QUIZ_5,
};

export const getQuizKeyFromOrder = (order) => {
  return QUIZ_ORDER[order];
};

export const getQuizOrderFromKey = (quizKey) => {
  const orderStr = Object.entries(QUIZ_ORDER).find((elem) => elem[1] === quizKey)[0];
  return parseInt(orderStr);
};
