import { createStore } from 'vuex';

import sessionModule, { SESSION_ACTIONS, SESSION_GETTERS } from './modules/session';

const store = createStore({
  modules: {
    session: sessionModule,
  },
});

const ACTIONS = { ...SESSION_ACTIONS };
const GETTERS = { ...SESSION_GETTERS };
export { ACTIONS, GETTERS };
export default store;
