<script setup>
import { computed, defineProps } from 'vue';
const props = defineProps({
  variant: {
    type: String,
    default: 'default',
    validator: (value) => {
      return value.match(/(default)/);
    },
  },
  class: {
    type: String,
    default: '',
  },
});

const classes = computed(() => `card ${props.variant} ${props.class}`);
</script>

<template>
  <div :class="classes" v-bind="$attrs">
    <slot></slot>
  </div>
</template>

<style scoped>
.card {
  @apply bg-white rounded-3xl shadow-xl;
}
</style>
