<script setup lang="ts"></script>

<template>
  <div>
    <div class="container app-container mx-auto px-4 lg:px-8 py-8 lg:py-10">
      <slot></slot>
    </div>
  </div>
</template>

<style scoped>
.app-container {
  max-width: 768px;
}
</style>
