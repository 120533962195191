<script setup>
import { reactive, defineProps, watch, computed, onMounted } from 'vue';
import Icon, { ICON_NAME } from '../base/Icon';
import Text from '../base/Text.vue';
import Button from '../base/Button';
import { RECORDING_STATUS } from './constants.js';

const INITIAL_STATE = {
  status: RECORDING_STATUS.NOT_STARTED,
  timerIntervalInstance: null,
  timeLeft: props.timeLimit,
  audioChunk: [],
  mediaRecorder: null,
  preRecordingTimerIntervalInstance: null,
  preRecordingTimeLeft: props.preRecordingTime,
};

const props = defineProps({
  timeLimit: { type: Number },
  preRecordingTime: { type: Number, default: 0 },
  autoRecord: { type: Boolean },
  onFinish: { type: Function, default: () => {} },
  onTimeup: { type: Function, default: () => {} },
  onUploaded: { type: Function, default: () => {} },
  onStopRecording: { type: Function, default: () => {} },
  uploadRecordingFunc: { type: Function },
});
const state = reactive({ ...INITIAL_STATE });

onMounted(() => {
  if (props.autoRecord) {
    startPreRecordingState();
  }
});

/**
 * All recording events will be handle in watch based on status value.
 * Event handlers will only change the status to trigger this.
 */
watch(
  () => state.status,
  async (newStatus) => {
    switch (newStatus) {
      case RECORDING_STATUS.PRE_RECORDING:
        startPreRecordingTimer();
        break;
      case RECORDING_STATUS.PREPARING:
        state.timeLeft = INITIAL_STATE.timeLeft;
        state.audioChunk = INITIAL_STATE.audioChunk;
        state.mediaRecorder = INITIAL_STATE.mediaRecorder;

        try {
          await setUpRecorder();
          state.status = RECORDING_STATUS.RECORDING;
        } catch (error) {
          console.error(error);
          state.status = RECORDING_STATUS.ERROR;
        }
        break;
      case RECORDING_STATUS.RECORDING:
        state.mediaRecorder.start();
        if (props.timeLimit) {
          startTimer();
        }
        break;
      case RECORDING_STATUS.STOPPING:
        if (state.timerIntervalInstance) {
          stopTimer();
        }
        state.mediaRecorder.stop();
        props.onStopRecording();
        state.status = RECORDING_STATUS.UPLOADING;
        break;
      case RECORDING_STATUS.UPLOADING:
        // Workaround: Set timeout to make sure all the audio data is available.
        setTimeout(async () => {
          try {
            await uploadRecording();
            await props.onUploaded();
            state.status = RECORDING_STATUS.FINISHED;
          } catch (error) {
            console.error(error);
            state.status = RECORDING_STATUS.ERROR;
          }
        }, 1000);
        break;
      case RECORDING_STATUS.FINISHED:
        state.isFinishedPopupOpen = true;
        props.onFinish();
        break;
      case RECORDING_STATUS.ERROR:
      default:
        alert('ขออภัยมีบางอย่างผิดพลาด กรุณารีเฟรชหน้าเว็บแล้วทำการทดสอบใหม่อีกครั้ง');
        location.reload();
    }
  }
);

/**
 * Call endpoint to upload the reecording.
 * TODO: Check filename pattern.
 */
const uploadRecording = async () => {
  const audioBlob = new Blob(state.audioChunk, { type: 'audio/wav' });
  await props.uploadRecordingFunc(audioBlob);
};

/**
 * Setup mediaRecorder and its event listeners.
 * If success, mediaRecorder instance should be available in state.
 */
const setUpRecorder = async () => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    const mediaRecorder = new MediaRecorder(stream);
    mediaRecorder.ondataavailable = (e) => {
      state.audioChunk.push(e.data);
    };
    state.mediaRecorder = mediaRecorder;
  } catch (error) {
    console.error(error);
    alert('Sorry, mediaDevices not supported!', error);
    location.reload();
  }
};

/**
 * Methods to control timer.
 * TODO: Check the precision of the timer. Might need to check with the actual datetime to calculate timeLeft.
 */
const startTimer = () => {
  state.timerLeft = props.timeLimit;
  state.timerIntervalInstance = setInterval(() => {
    state.timeLeft--;
    if (state.timeLeft <= 0) {
      props.onTimeup();
      handleStopRecording();
    }
  }, 1000);
};

const stopTimer = () => {
  clearInterval(state.timerIntervalInstance);
  state.timerIntervalInstance = null;
};

/**
 * Pre recording functions
 */
const startPreRecordingState = () => {
  state.status = RECORDING_STATUS.PRE_RECORDING;
};
const startPreRecordingTimer = () => {
  state.preRecordingTimeLeft = props.preRecordingTime;
  state.preRecordingTimerIntervalInstance = setInterval(() => {
    state.preRecordingTimeLeft--;
    if (state.preRecordingTimeLeft <= 0) {
      stopPreRecordingTimer();
    }
  }, 1000);
};
const stopPreRecordingTimer = () => {
  handleStartRecording();
  clearInterval(state.preRecordingTimerIntervalInstance);
  state.preRecordingTimerIntervalInstance = null;
};

/**
 * UI event handlers.
 */
const handleStopPreRecordingTimer = () => {
  stopPreRecordingTimer();
};

const handleStartRecording = async () => {
  state.status = RECORDING_STATUS.PREPARING;
};

const handleStopRecording = async () => {
  state.status = RECORDING_STATUS.STOPPING;
};

const handleToggleRecording = () => {
  if (canStartRecording.value) {
    handleStartRecording();
    return; // return to stop function.
  }

  /**
   * We are testing the experience to not allowing users to stop recording by themselves.
   * Remove the condition below completely if this experience win.
   */
  // if (canStopRecording.value) {
  //   handleStopRecording();
  //   return;
  // }
};

/**
 * Computed variables
 */
const canStartRecording = computed(() => {
  const isNotStartedState = state.status === RECORDING_STATUS.NOT_STARTED;
  const isPreRecordingState = state.status === RECORDING_STATUS.PRE_RECORDING;
  return isNotStartedState || isPreRecordingState;
});
// const canStopRecording = computed(() => {
//   return state.status === RECORDING_STATUS.RECORDING;
// });
const isRecording = computed(() => {
  return state.status === RECORDING_STATUS.RECORDING;
});
const showPreRecordingTimerBar = computed(() => {
  return state.status === RECORDING_STATUS.PRE_RECORDING && props.autoRecord;
});
</script>

<template>
  <div v-if="showPreRecordingTimerBar">
    <div
      class="rounded-2xl shadow-xl bg-yellow-600 py-5 px-6 w-full mb-4 flex flex-col items-center"
    >
      <Text variant="medium" class="font-medium text-center mb-4">
        เหลือเวลาอ่านคำถามอีก {{ state.preRecordingTimeLeft }} วินาที <br />
      </Text>
      <Text variant="normal" class="text-center mb-4">
        เมื่ออ่านคำถามจบแล้ว คุณสามารถกดปุ่มด้านล่างเพื่อเริ่มอัดเสียงทันที
      </Text>
      <Button variant="primary" v-on:click="handleStopPreRecordingTimer">เริ่มบันทึกเสียง</Button>
    </div>

    <Text variant="small" class="font-regular text-center">
      คุณสามารถย้อนกลับมาอ่านคำถามในระหว่างการอัดเสียงได้ แต่เวลาจะไม่หยุดเดิน
    </Text>
  </div>
  <div
    v-else
    class="button-container flex items-center gap-3 rounded-full pl-10 pr-7 py-4 max-w-xs h-18"
    v-on:click="handleToggleRecording"
    :class="
      isRecording ? 'bg-red-700 cursor-default button-is-recording' : 'bg-white cursor-pointer'
    "
  >
    <div class="flex-grow">
      <div v-if="isRecording">
        <Text variant="tiny" class="text-right text-white"> กำลังบันทึกเสียง...<br /> </Text>
        <Text v-if="props.timeLimit" class="text-right text-white">
          เหลือเวลา <span class="w-6 inline-block text-center">{{ state.timeLeft }}</span> วินาที
        </Text>
      </div>
      <Text v-if="canStartRecording" variant="small" class="text-right">
        กดปุ่มเพื่อเริ่มบันทึกเสียง และระบบจะหยุดบันทึกอัติโนมัติเมื่อเวลาหมด
      </Text>
      <!-- Check this behaviour -->
      <Text v-if="state.status === RECORDING_STATUS.FINISHED">บันทึกเสียงเสร็จสิ้น</Text>
    </div>

    <div class="icon-container flex-shrink-0" v-bind="$attrs">
      <div v-if="canStartRecording" v-on:click="handleStartRecording" class="w-full h-full">
        <Icon :iconName="ICON_NAME.MICROPHONE" class="button-icon text-red-500" />
      </div>
      <!-- <div v-if="canStopRecording" v-on:click="handleStopRecording">
        <Icon :iconName="ICON_NAME.STOP" class="button-icon text-white" />
      </div> -->
    </div>
  </div>
</template>

<style scoped>
.button-container {
  @apply cursor-pointer shadow-md;
}
.button-container:hover {
  @apply cursor-pointer shadow-lg;
}
.button-container:active {
  @apply cursor-pointer shadow-sm;
}
.button-is-recording {
  @apply !shadow-none;
}

.icon-container {
  width: 40px;
  height: 40px;
}
.button-icon {
  width: 100%;
  height: 100%;
}
</style>
