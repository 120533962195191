<script setup></script>

<template>
  <img src="/assets/images/loading.svg" class="loading-img" />
</template>

<style scoped>
.loading-img {
  width: 120px;
}
</style>
