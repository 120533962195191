import { TESTER_DATA_KEY } from './tester';

export const DATA_KEY = {
  CODE_TYPE: 'codeType',
  SCHOOL_NAME: 'schoolName',
  SCHOOL_CODE: 'schoolCode',
  TEST_ID: 'testId',
  /**
   * STUDENT_NAME, STUDENT_GRADE, STUDENT_ROOM are now deprecated.
   * Switch to use TESTER_DATA_KEY instead.
   */
  STUDENT_PREFIX: TESTER_DATA_KEY.TESTER_PREFIX,
  STUDENT_FIRSTNAME: TESTER_DATA_KEY.TESTER_FIRSTNAME,
  STUDENT_LASTNAME: TESTER_DATA_KEY.TESTER_LASTNAME,
  STUDENT_NAME: TESTER_DATA_KEY.TESTER_NAME,
  STUDENT_GRADE: TESTER_DATA_KEY.TESTER_GRADE,
  STUDENT_ROOM: TESTER_DATA_KEY.TESTER_ROOM,
  SESSION_KEY: 'sessionKey',
  CURRENT_QUIZ: 'currentQuiz',
  QUIZ_STATUS: 'quizStatus', // Value of quizStatus is QUIZ_KEY
};

export const QUIZ_KEY = {
  QUIZ_1: 'quiz1',
  QUIZ_2: 'quiz2',
  QUIZ_3: 'quiz3',
  QUIZ_4: 'quiz4',
  QUIZ_5: 'quiz5',
  // QUIZ_6: 'quiz6',
};

export const CODE_TYPE = {
  TEACHER: 'TEACHER',
  STUDENT: 'STUDENT',
};

export { TESTER_DATA_KEY };
