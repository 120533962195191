import { DATA_KEY } from '../../../constants';

const state = {
  [DATA_KEY.CODE_TYPE]: '',
  [DATA_KEY.SCHOOL_CODE]: '',
  [DATA_KEY.SCHOOL_NAME]: '',
  [DATA_KEY.TEST_ID]: '',
  [DATA_KEY.STUDENT_NAME]: '',
  [DATA_KEY.STUDENT_GRADE]: '',
  [DATA_KEY.STUDENT_ROOM]: '',
  [DATA_KEY.SESSION_KEY]: '',
  [DATA_KEY.CURRENT_QUIZ]: '', // If '', the quiz has not started yet.
  [DATA_KEY.QUIZ_STATUS]: {},
};

export default state;
