export const TESTER_DATA_KEY = {
  TESTER_PREFIX: 'testerPrefix',
  TESTER_FIRSTNAME: 'testerFirstName',
  TESTER_LASTNAME: 'testerLastName',
  TESTER_NAME: 'testerName',
  TESTER_GRADE: 'testerGrade',
  TESTER_ROOM: 'testerRoom',
  TESTER_EMAIL: 'testerEmail',
  TESTER_PHONE_NO: 'testerPhoneNo',
  TESTER_SCHOOL: 'testerSchoolName',
};
