<script setup>
import { reactive, defineEmits } from 'vue';
import Button from '../../components/base/Button.vue';
import Input from '../../components/form/Input.vue';
import ErrorMessage from '../../components/form/ErrorMessage.vue';

// TODO: Check validation requirement
const FIELDNAME = ['firstname', 'lastname', 'school', 'email', 'phoneNo'];

const state = reactive({
  prefix: '',
  firstname: '',
  lastname: '',
  school: '',
  email: '',
  phoneNo: '',
  formIsValid: false,
  showErrorMessage: false,
});

const emit = defineEmits(['saveData']);

const validateForm = () => {
  const isValid = FIELDNAME.every((field) => {
    return state[field].length > 0;
  });
  state.showErrorMessage = !isValid;
  state.formIsValid = isValid;
  return isValid;
};

const handleSubmit = () => {
  validateForm();
  if (state.formIsValid) {
    const formData = {
      prefix: state.prefix, // This is always an empty string, but keep it for consistency.
      firstname: state.firstname,
      lastname: state.lastname,
      fullname: `${state.firstname} ${state.lastname}`,
      school: state.school,
      email: state.email,
      phoneNo: state.phoneNo,
    };
    emit('saveData', formData);
  }
};

const handleChange = (e) => {
  state[e.target.id] = e.target.value;
  validateForm();
};
</script>

<template>
  <div class="flex gap-4 mb-4">
    <Input
      id="firstname"
      class="flex-grow"
      :value="state.firstname"
      @input="handleChange"
      placeholder="ชื่อ"
    />
    <Input
      id="lastname"
      class="flex-grow"
      :value="state.lastname"
      @input="handleChange"
      placeholder="นามสกุล"
    />
  </div>
  <Input
    id="school"
    class="mb-4"
    :value="state.school"
    @input="handleChange"
    placeholder="โรงเรียน / สังกัด"
  />
  <div class="flex gap-4 mb-10">
    <Input
      id="email"
      class="flex-grow"
      :value="state.email"
      @input="handleChange"
      placeholder="อีเมล"
    />
    <Input
      id="phoneNo"
      class="flex-grow"
      :value="state.phoneNo"
      @input="handleChange"
      placeholder="เบอร์โทร"
    />
  </div>

  <div class="w-full flex justify-center mb-4">
    <Button size="large" v-on:click="handleSubmit" :disabled="!state.formIsValid">
      ลงทะเบียน
    </Button>
  </div>
  <ErrorMessage :message="state.showErrorMessage && 'กรุณากรอกข้อมูลให้ครบทุกช่อง'" />
</template>

<style scoped>
.dropdown-button {
  @apply rounded-full border flex pr-5 font-semibold border-primary text-grey-900 items-center justify-between cursor-pointer h-[50px];
}
</style>
